import React from 'react'
import { Link } from 'react-router-dom';

import { faCloud, faScaleBalanced} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

function Footer() {
  return (
    <footer className='footer'>
      <Link to='/contact'className='footer__contact'>
        {/* <FontAwesomeIcon icon={faPhoneFlip} className='footer__contact__icon'/> */}
        <FontAwesomeIcon icon={faEnvelope} className='footer__contact__icon'/>
        <p className='footer__contact__title'>Nous contacter</p>
      </Link>
      {/*       
      <div className='footer__contact'>
        <FontAwesomeIcon icon={faPhoneFlip} className='footer__contact__icon'/>
        <p className='footer__contact__title'>Nous contacter</p>
      </div> 
      */}

      <div className='footer__name'>
        <FontAwesomeIcon icon={faCloud} className='footer__name__icon'/>
        <h2 className='footer__name__title'>Cloudy Pocket</h2>
      </div>

      <Link to='/legal' className='footer__legal'>
        <FontAwesomeIcon icon={faScaleBalanced} />
        <p>Conditions générales</p>
      </Link>

    </footer>
  )
}

export default Footer