import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import { GlobalContext } from '../../contexts/GlobalContext'
import Dashboard from '../../pages/Dashboard'

import { listenEscapeKey, closeContent, clickOutModal } from '../../utils/functions'
import { DashboardContext } from '../../contexts/DashboardContext'
import { API_FUNCTIONS } from '../../utils/apiFunctions'
import { useForm } from 'react-hook-form'
import { TOASTS } from '../../utils/toasts'

function UpdateSubcategoryModal({subcategories, updateSubcategory}) {
    const {token} = useContext(GlobalContext)
    const {categories} = useContext(DashboardContext)
    const [newName, setNewName] = useState('')
    const [replaceSubcategory, setReplaceSubcategory] = useState('')
    const [interactionType, setInteractionType ] = useState('') // can be 'update' or 'delete'

    const [selectedSubcategory, setSelectedSubcategory] = useState("")


    const updateSub =async(e)=>{
        e.preventDefault()
        const request={
            id: selectedSubcategory,
            subcategory_name: newName,
            category_id: subcategories.find(item => item.id === selectedSubcategory).category_id
        }
        try{
            const response = await API_FUNCTIONS.updateSubcategory(request, token)
            if(response.data.code === 200){
                updateSubcategory(response.data.data)
                document.getElementById('updateSubcategoryForm').reset()
                setSelectedSubcategory('')
                TOASTS.success('La sous-catégorie a bien été mise à jour')
                closeContent('updateSubcategoryModal')
            }else{
                TOASTS.error('Oups, une erreur est survenue lors de la modification de la sous-catégorie...')
            }
        }catch(e){
            TOASTS.error('Oups, une erreur est survenue lors de la modification de la sous-catégorie...')
            console.error(e);
        }
    }

    const deleteSub = async(e)=>{
        e.preventDefault()
        const request= {
            replace: parseInt(replaceSubcategory)
        }
        try{
            const response = await API_FUNCTIONS.deleteSubcategory(request, selectedSubcategory, token)
            console.log("response delete", response)
            if(response.data?.code === 200){
                TOASTS.success('La sous-catégorie a bien été supprimée')
                setReplaceSubcategory('')
            }else{
                TOASTS.error('Oups, une erreur est survenue lors de la suppression de la sous-catégorie...')
            }
        }catch(e){
            TOASTS.error('Oups, une erreur est survenue lors de la suppression de la sous-catégorie...')
            console.error(e);
        }
    }

    useEffect(() => {
        selectedSubcategory !== "" && setNewName(subcategories.find(item => item.id === parseInt(selectedSubcategory)).subcategory_name)
        selectedSubcategory !== "" && setInteractionType('')
    }, [selectedSubcategory])


    return (
        <div className='v2modal updateSubcategoryModal' id='updateSubcategoryModal' onClick={(e) => clickOutModal(e, 'updateSubcategoryModal')}>
            <div className='v2modal__content updateSubcategoryModal__content'> 
                <FontAwesomeIcon icon={faXmark} alt="fermer" onClick={() => closeContent('updateSubcategoryModal')}  className='v2modal__closeButton'/>
                <h2>Modifier une sous-catégorie</h2>
    
                <form>
                    <label>Veuillez choisir une sous-catégorie</label>
                    <select onChange={(e) => setSelectedSubcategory(parseInt(e.target.value))}>
                        <option value={""}></option>
                        {subcategories.map((subcat, index) => 
                            <option value={subcat.id} key={index}>{subcat.subcategory_name}</option>
                        )}
                    </select>
                </form>

                {selectedSubcategory !== '' &&
                    <div className='updateSubcategoryModal__selectChoice'>
                        <h3>Que voulez-vous faire ?</h3> 
                        <div className='updateSubcategoryModal__selectChoice__choose'>
                            <span>Modifier</span>
                                <div onClick={() => setInteractionType(interactionType === 'update' ? 'delete' : 'update')} className='updateSubcategoryModal__selectChoice__choose__toggle'>
                                    <div style={{left: interactionType === 'delete' ? 'calc( 100% - 12px )' : interactionType === 'update'&& '0'}}></div>
                                </div>
                            <span>Supprimer</span>
                        </div>
                    </div>
                }
                {selectedSubcategory !== '' && interactionType === 'update' &&
                    <div className='updateSubcategoryModal__update'>
                        <h3>Modifier</h3>
                        <form onSubmit={(e) => updateSub(e)} id="updateSubcategoryForm">
                            <p>Modifier la sous-catégorie</p>
                            <input type="text" value={newName} onChange={e => setNewName(e.target.value)}/>
                            <button type="submit">Enregistrer</button>
                        </form>  
                    </div>
                }
                
                {selectedSubcategory !== '' && interactionType === 'delete' &&
                    <div className='updateSubcategoryModal__delete'>
                        <h3>Supprimer</h3>
                        
                        <form onSubmit={e => deleteSub(e)}>
                            <label>Remplacer les lignes concernées par une autre sous-catégorie ? </label>
                            <select onChange={e => setReplaceSubcategory(e.target.value)}>
                                <option></option>
                                {subcategories.filter(item => item.id !== parseInt(selectedSubcategory)).map((sub, index) =>
                                    <option value={sub.id} key={index}>{sub.subcategory_name}</option>
                                )}
                            </select>
                            <button type="submit">Supprimer</button>
                        </form>
                    </div>
                }
                
                
            </div>
        </div>
    )
}

export default UpdateSubcategoryModal