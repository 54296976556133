import React, {useContext, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImages, faXmark } from '@fortawesome/free-solid-svg-icons'

import { clickOutModal, closeContent, listenEscapeKey } from '../../utils/functions'
import { GlobalContext } from '../../contexts/GlobalContext'
import { API_FUNCTIONS } from '../../utils/apiFunctions'
import { TOASTS } from '../../utils/toasts'

function NewTicketModal({handleAddTicket}) {
    const { token, user } = useContext(GlobalContext)
    const { register, handleSubmit, watch, setValue, formState: {errors}} = useForm()
    const [previewImage, setPreviewImage] = useState(null)

    const newTicket = watch('ticket_name')

    const addTicket = async(data) => {
        try{
            const request = new FormData()
            request.append('ticket_name', data.ticket_name[0])
            request.append('account_id', user.id)
            const response = await API_FUNCTIONS.addTicket(request, token)

            setPreviewImage(null)
            if(response.data?.code === 201){
                handleAddTicket(response.data.data)
                document.getElementById('newTicketForm').reset()
                closeContent('newTicketModal')
                TOASTS.success('Le ticket a bien été enregistré')
            }
        }catch(e){
          console.error(e);
          TOASTS.error("Une erreur s'est produite")
        }
      }
    
    useEffect(() => {
        
        if(newTicket?.length > 0){
            const reader = new FileReader()
            reader.onloadend = () => {
                setPreviewImage(reader.result)
            }
            reader.readAsDataURL(newTicket[0])
        }else{
            setPreviewImage(null)
        }
    }, [newTicket])
    


    listenEscapeKey('newTicketModal')
    return (
        <div className='modal newTicketModal' id='newTicketModal' onClick={(e) => clickOutModal(e, 'newTicketModal')}>
            <div className='modal__content newTicketModal__content'> 
                <FontAwesomeIcon icon={faXmark} alt="fermer" onClick={() => closeContent('newTicketModal')}  className='modal__closeButton'/>
                <h2 className=''>Ajouter un ticket de caisse</h2>

                <form className="newTicketForm" id="newTicketForm" onSubmit={handleSubmit(addTicket)}>
                    
                    {previewImage && <span onClick={() => setValue('ticket_name', null)} className='newTicketForm__deleteButton'>Supprimer</span>}
                    <label>
                        <div className="newTicketForm__preview">
                            {previewImage ?
                                <img src={previewImage} className="newTicketForm__preview__img" alt="Aperçu du ticket" />
                            :
                            <div className='newTicketForm__preview__noImage' >
                                <FontAwesomeIcon icon={faImages} className='newTicketForm__preview__noImage__icon'/>
                                <p className='newTicketForm__preview__noImage__text'>Cliquez ici pour ajouter un ticket</p>
                            </div>
                        }
                        </div>
                        {/* <span>Sélectionner un ticket</span> */}
                        <input type="file" accept="image/*" /*onChange={handleFileChange}*/{...register('ticket_name', {required: 'Ce champ est obligatoire'})}/>
                    </label>

                    { newTicket?.length > 0 &&<button type='submit' >Envoyer</button>}

                </form>

            </div>
        </div>
    )
}

export default NewTicketModal