import React, {useContext, useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { closeContent, clickOutModal } from '../../utils/functions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBox, faPen, faTrash, faUserPlus, faXmark } from '@fortawesome/free-solid-svg-icons'

import { GlobalContext } from '../../contexts/GlobalContext'
import { DashboardContext } from '../../contexts/DashboardContext'
import { API_FUNCTIONS, API_STORAGE } from '../../utils/apiFunctions'
import { TOASTS } from '../../utils/toasts'

function AccountParametersModal({account}) {
    const {register, handleSubmit, formState: {errors}, reset} = useForm()
    const {token} = useContext(GlobalContext)
    const {setAccounts, sharedWith, setSharedWith} = useContext(DashboardContext)
    const {setDbUrl} = useContext(DashboardContext)

    const [isLoaded, setIsLoaded] = useState(false)
    const [isUpdatingAccount, setIsUpdatingAccount] = useState(false)
    const [ bankLogo, setBankLogo ] = useState([])

    /**
     * LOADING DATAS
     */

    const loadingDatas = async() => {
        try{
            const [sharesDatas, logoDatas] = await Promise.all([
                API_FUNCTIONS.shared(account.id, token), // Get users with whom the account is shared
                API_FUNCTIONS.oneLogo(account.id, token) // Get the account logo's link
            ])
            
            if(sharesDatas.data?.code === 200){
                const users = []
                sharesDatas.data.data.forEach(data => users.push(data.user))
                setSharedWith(users)
            }else{
                TOASTS.error('Oups, une erreur est survenue lors du chargement des utilisateurs partagés...')
            }

            if(logoDatas.data?.code === 200){
                setBankLogo(logoDatas.data.data)
            }else{
                TOASTS.error('Oups, une erreur est survenue lors du chargement du logo...')
            }
        }catch(e){
            console.error(e)
        }finally{
            setIsLoaded(true)
        }
    }

    useEffect(() => {
        loadingDatas()
    }, [])

    
    /**
     * API FUNCTIONS
     */


    const createLogo = async(file) => {
        const data = new FormData()
        data.append('logo_name', file)
        data.append('account_id', account.id)
        try{
            const response = await API_FUNCTIONS.addLogo(data, token)
            if(response.data?.code === 201){
                setBankLogo(response.data.data)
                TOASTS.success('Le logo a bien été ajouté')
            }
        }catch(e){
            console.error(e);
            TOASTS.error('Oups, une erreur est survenue')
        }
    }
    const deleteLogo = async(id) => {
        try{
            const response = await API_FUNCTIONS.deleteLogo(id, token)
            if(response.data?.code === 200){
                setBankLogo([])
                TOASTS.success('Le logo a bien été supprimé')
            }
        }catch(e){
            console.error(e);
            TOASTS.error('Oups, une erreur est survenue...')
        }
    }
    const unshare = async(user)=>{

        try{
            const response = await API_FUNCTIONS.unshare({email: user.email}, account.id, token)
            if(response.data?.code === 200){
                // setSharedWith(prev=> prev.filter(item => item.id !== user.id))
                // handleUnshareAccount(user.id)
                setSharedWith(prev => prev.filter(item => item.id !== user.id))
                TOASTS.success('Le partage avec '+user.firstname+' '+user.lastname+' a bien été supprimé')
            }
        }catch(e){
            console.error(e);
            TOASTS.error('Oups, une erreur est survenue...')
        }
    }

    const archiveAccount=async()=>{
        try{
            const response = await API_FUNCTIONS.archive(account.id, token)
            if(response.data?.code === 200){
                setDbUrl('dbAccounts')
                TOASTS.success('Le compte "'+account.account_name+ '" a bien été archivé')
            }
        }catch(e){
            console.error(e);
            TOASTS.error('Oups, une erreur est survenue...')
        }
    }
    const deleteAccount = async() => {
        try{
            const response = await API_FUNCTIONS.deleteAccount(account.id, token)
            if(response.data?.code === 200){
                TOASTS.success('Le compte "'+account.account_name+'" a bien été supprimé')
                setAccounts(prev => prev.filter(item => item.id !== account.id))
                setDbUrl('dbAccounts')
            }
        }catch(e){
            console.error(e);
            TOASTS.error('Oups, une erreur est survenue...')
        }
    }

    const updateAccount = async(data) => {
        try{
            const response = await API_FUNCTIONS.updateAccount(data, account.id, token)
            if(response.data?.code === 200){
                account.account_name = response.data.data.account_name
                account.account_bank = response.data.data.account_bank
                account.account_bank_adress = response.data.data.account_bank_adress
                account.account_iban = response.data.data.account_iban
                account.account_bic = response.data.data.account_bic
                document.getElementById('updateAccountForm').reset()
                reset()
                setIsUpdatingAccount(false)
                TOASTS.success('Les informations ont bien été enregistrées')
            }
        }catch(e){
            console.error(e);
            TOASTS.error('Oups, une erreur est survenue...')
        }
    }

    
    
  return (
    <div className='v2modal accountParametersModal' id='accountParametersModal' onClick={(e) => clickOutModal(e, 'accountParametersModal')}>
        <div className='v2modal__content accountParametersModal__content'> 
            <FontAwesomeIcon icon={faXmark} alt="fermer" onClick={() => closeContent('accountParametersModal')}  className='v2modal__closeButton'/>
            <h2 className='danger'>Paramètres Avancés</h2>
            {
                sharedWith.length!==0 && <p>Propriétaire du compte : {sharedWith.find(item => item.id === account.account_owner).firstname} {sharedWith.find(item => item.id === account.account_owner).lastname[0]}.</p>
            }

            <h3>Informations du compte</h3>
            <div className='accountParametersModal__logo'>
                {bankLogo?.logo_name && isLoaded ? 
                    <div className='accountParametersModal__logo__view'>
                        <FontAwesomeIcon icon={faTrash} onClick={e => {e.preventDefault(); deleteLogo(bankLogo.id)}}/>
                        <img src={API_STORAGE+bankLogo.logo_name} alt="Logo de la banque"/>
                        {/* <button onClick={e => {e.preventDefault(); deleteLogo(bankLogo.id)}}></button> */}
                    </div>
                :
                    // <input type='file' onChange={e => e && createLogo(e.target.files[0])} className='accountParametersModal__logo__input'/>
                    <label className='accountParametersModal__button accountParametersModal__logo__add'>
                        Ajouter un logo
                        <input type='file' onChange={e => e && createLogo(e.target.files[0])} accept="image/*"/>
                    </label>
                }
            </div>
            {
                isUpdatingAccount ?
                    <form id='updateAccountForm' onSubmit={handleSubmit(updateAccount)} className='accountParametersModal__bankForm'>
                        <button onClick={e => {e.preventDefault(); setIsUpdatingAccount(false); reset()}} className='accountParametersModal__bankForm__cancel'>Annuler</button>
                        <label>Nom du compte</label>
                        <input type="text" defaultValue={account.account_name} {
                            ...register('account_name',
                            {required: "Le compte doit avoir un nom"}
                        )}/>
                        <label>Banque</label>
                        <input type='text' defaultValue={account.account_bank || ''} {...register('account_bank')}/>
                        
                        <label>Adresse de la banque</label>
                        <input type='text' defaultValue={account.account_bank_adress || ''} {...register('account_bank_adress')}/>
                        
                        <label>IBAN</label>
                        <input type='text' defaultValue={account.account_iban || ''} {...register('account_iban')}/>
                        
                        <label>BIC</label>
                        <input type='text' defaultValue={account.account_bic || ''} {...register('account_bic')}/>

                        <button type="submit" className='accountParametersModal__bankForm__submit'>Envoyer</button>
                    </form>
                :
                    <div className='accountParametersModal__bank'>
                        <table className='accountParametersModal__bank__table'>
                            <tbody>
                                <tr>
                                    <td>Nom</td>
                                    <td>{account.account_name}</td>
                                </tr>
                                <tr>
                                    <td>Banque</td>
                                    <td>{account.account_bank || "Pas encore de nom"}</td>
                                </tr>
                                <tr>
                                    <td>Adresse</td>
                                    <td>{account.account_bank_adress || "Pas encore d'adresse"}</td>
                                </tr>
                                <tr>
                                    <td>IBAN</td>
                                    <td>{account.account_bank_iban || "Pas encore d'IBAN"}</td>
                                </tr>
                                <tr>
                                    <td>BIC</td>
                                    <td>{account.account_bank_bic || "Pas encore de BIC"}</td>
                                </tr>
                            </tbody>
                        </table>
                        <button onClick={() => setIsUpdatingAccount(true)} className='accountParametersModal__button accountParametersModal__bank__updateButton'>Modifier les informations</button>
                    </div>
            }


            {
                isLoaded &&
                <div>
                    <h3><FontAwesomeIcon icon={faUserPlus}/> Compte partagé avec</h3> 
                    {sharedWith.filter(item => item.id !== account.account_owner).map((user, index) => 
                        <div key={index}>{user.firstname} {user.lastname[0]}. <button onClick={() => unshare(user)} >Supprimer le partage</button></div>
                    )}
                </div>
            }
            
            <h3><FontAwesomeIcon icon={faBox}/> Archiver le compte</h3>
            <p className='accountParametersModal__archive__description'>Archiver le compte permet de le retirer des comptes actifs sans le supprimer. Il sera toujours possible de le consulter depuis l'onglet "paramètres" du tableau de bord mais il ne sera plus possible de le modifier ou de rajouter des lignes de transactions.</p>

            <button onClick={() => archiveAccount()} className='accountParametersModal__button accountParametersModal__archive__button'>Archiver le compte</button>

            <h3 className='danger'><FontAwesomeIcon icon={faTrash}/> Supprimer le compte</h3>
            <p>Attention toute suppression de compte est <b>définitive</b>.</p>
            <button onClick={() => deleteAccount()} className='accountParametersModal__button accountParametersModal__delete__button'>Supprimer le compte</button>

            

            
        </div>
    </div>
  )
}

export default AccountParametersModal