import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../contexts/GlobalContext'

import { API_FUNCTIONS } from '../../utils/apiFunctions'
import { API_STORAGE } from '../../utils/apiFunctions'

import NewTicketModal from '../modals/NewTicketModal'
import { openContent } from '../../utils/functions'
import { TOASTS } from '../../utils/toasts'


function DBTickets() {
  const {token, user} = useContext(GlobalContext)

  const [ isLoaded, setIsLoaded ] = useState(false)
  const [ tickets, setTickets ] = useState([])
  const [today, setToday] = useState(null)

  const REMAINING_TIME = 30

  const getTimeLeft = (ticketDate) =>{
    const TODAY = new Date(today)
    const ticketCreatedAt = new Date(ticketDate)
    const difference = TODAY.getTime() - ticketCreatedAt.getTime()
    return parseInt(Math.abs(REMAINING_TIME - difference/ (1000 * 3600 * 24)))
  }

  const getCurrentDate = () => {
    const date = new Date()
    const year = date.getFullYear()
    const month = ('0' + (date.getMonth() + 1)).slice(-2)
    const day = ('0' + date.getDate()).slice(-2)
    setToday(`${year}-${month}-${day}`)
  }

  const loadingTickets = async()=>{
    try{
      const response = await API_FUNCTIONS.allTickets(token)
      if(response.data?.code === 200){
        const newTickets = response.data.data.map(ticket => {
          const timeLeft = getTimeLeft(ticket.created_at)
          if(timeLeft > 0){
            return {
              ...ticket,
              timeLeft: timeLeft
            }
          }else{
            deleteTicket(response.data.data.id)
          }
        })
        setTickets(newTickets)
      }else{
        TOASTS.error('Erreur lors du chargement des tickets')
      }
    }catch(e){
      console.error(e);
      TOASTS.error('Erreur lors du chargement des tickets')
    }finally{
      setIsLoaded(true)
    }
  }

  const deleteTicket = async(id) => {
    try{
      const response = await API_FUNCTIONS.deleteTicket(id, token)
      if(response.data?.code === 200 ){
        setTickets(prev => prev.filter(item => item.id !== id))
        TOASTS.success('Le ticket a bien été supprimé')
      }else{
        TOASTS.error('Une erreur est survenue')
      }
    }catch(e){
      console.error(e);
      TOASTS.error('Une erreur est survenue')
    }
  }


  const handleAddTicket = (newTicket)=> {
    const ticket = {
      ...newTicket,
      timeLeft: getTimeLeft(newTicket.created_at)
    }
    setTickets(prev => [...prev, ticket])
  }


  useEffect(() => {
    if(today && token && !isLoaded){
      loadingTickets()
    }else{
      getCurrentDate()
    }
  }, [token, today])


  
  return (
    <div className='tickets'>
      
      <p className='tickets__description'>Cet espace permet de stocker momentanément vos tickets de caisse pour les rentrer plus tard dans vos comptes.</p>
      <p className='tickets__description'>Chaque ticket enregistré est disponible pour une durée de {REMAINING_TIME} jours. Après ce délais il sera automatiquement supprimé.</p>
      <button className="tickets__addButton" onClick={() => openContent('newTicketModal')}>Ajouter un ticket</button>

      {/* <h2 className='tickets__title'>Mes tickets</h2> */}
      { isLoaded && <div className='tickets__container'>
        {
          tickets.length !== 0 ?
            tickets.map(ticket => 
            <div key={ticket.id} className='tickets__container__one'>
              <span className={'tickets__container__one__timeLeft ' + (ticket.timeLeft <= 5 && (ticket.timeLeft <=3 ? 'danger' : 'warning'))}>Temps restant<br/>{ticket.timeLeft} {ticket.timeLeft > 1 ? 'jours' : 'jour'}</span>
              <img className='tickets__container__one__img' src={API_STORAGE+ticket.ticket_name} alt={'ticket '+ticket.ticket_name}/>
              <button className='tickets__container__one__delete' onClick={() => deleteTicket(ticket.id)}>Supprimer</button>
            </div>
            )
          :
            <p>Pas encore de ticket</p>
        }
      </div>}

      <NewTicketModal handleAddTicket={handleAddTicket}/>
    </div>
  )
}

export default DBTickets