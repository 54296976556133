import React, {useContext, useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { GlobalContext } from '../../contexts/GlobalContext'
import { DashboardContext } from '../../contexts/DashboardContext'
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function DBHeader() {
  const { user } = useContext(GlobalContext)
  const { dbUrl, currentAccount } = useContext(DashboardContext)

  
  return (
    <header className='dashboardHeader'>
        <nav>
            <Link to="/"><FontAwesomeIcon icon={faArrowRightFromBracket}/></Link>
            
        </nav>
        <h1>
          {dbUrl === 'dbAccounts' && 'Mes Comptes'}
          {dbUrl === 'dbOneAccount' && currentAccount?.account_name}
          {dbUrl === 'dbTickets' && 'Mes Tickets'}
          {dbUrl === 'dbSettings' && 'Paramètres'}
          {dbUrl === 'dbAdmin' && 'Administration'}
        </h1>
        {user.length !== 0 && <div className='dashboardHeader__user'>
            <div className='dashboardHeader__user__logo'>{user && user.firstname[0]}</div>
            <span className='dashboardHeader__user__name'>{user && user.firstname} {user && user.lastname[0]}</span>
          </div>}
    </header>
  )
}

export default DBHeader