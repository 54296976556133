import React, { useContext, useEffect } from 'react'
import {useForm} from 'react-hook-form'
import { API_FUNCTIONS } from '../utils/apiFunctions'
import { TOASTS } from '../utils/toasts'
import { GlobalContext } from '../contexts/GlobalContext'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

function Contact() {

    const {register, handleSubmit, setValue, formState: {errors}, reset} = useForm()
    const {user, setUrl} = useContext(GlobalContext)

    setUrl(useLocation().pathname)

    useEffect(() => {
      if(user.email) {
        setValue('email', user.email);
      }
    }, [user]);
    

    const sendMessage =async(data) => {
      try{
        const response = await API_FUNCTIONS.sendMessage(data)

        if(response.data?.code === 200){
          reset()
          TOASTS.success('Votre message a bien été envoyé. Nous vous recontacterons dans les meilleus délais.')
        }else{
          TOASTS.error("Oups, une erreur est survenue lors de l'envoi de votre message...")
        }
      }catch(e){
        TOASTS.error("Oups, une erreur est survenue lors de l'envoi de votre message...")
      }
    }
  return (
    <div className='mainContainer contact'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact - Cloudy Pocket</title>
      </Helmet>
      <h1>Nous contacter</h1>

      <p className='contact__description'>Vous pouvez nous contacter en remplissant le formulaire ci-dessous et nous vous recontacterons le plus rapidement possible.</p>

      <form onSubmit={handleSubmit(sendMessage)} id="contactForm" className=''>
        <label htmlFor="contactEmail">Votre email</label>
        <input type="text" autoComplete="off" id='contactEmail'{...register('email',  {required: "Ce champ est obligatoire"})} placeholder=''/>
        {errors.email && <span className='danger loginForm__error'>{errors.email.message}</span>}
        
        <label htmlFor="loginPassword">Votre message</label>
        <textarea id='contactMessage'{...register('message', {required: "Vous ne pouvez pas envoyer un message vide"})} placeholder=''/>
        {/* {errors.message && <span className='danger loginForm__error'>{errors.message.message}</span>} */}

        <button type='submit'>Envoyer</button>
      </form>
      
    </div>
  )
}

export default Contact