import React, {useContext, useEffect, useState} from 'react'
import { useForm } from 'react-hook-form'

import { DashboardContext } from '../contexts/DashboardContext'
import { GlobalContext } from '../contexts/GlobalContext'
import { API_FUNCTIONS } from '../utils/apiFunctions'
import {TOASTS} from '../utils/toasts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { getElementWidth } from '../utils/functions'

function NewTransactionForm({currentCategoryId, currentSubcategory, currentAccount, handleAddTransaction, handleSelectCategory, handleToggleTransactionForm}) {
    const { token, mobileLimit } = useContext(GlobalContext)
    const { transactionTypes, categories } = useContext(DashboardContext)
    const {register, handleSubmit, formState:{errors}} = useForm()



    const addTransaction = async(data)=>{
        try{
            const request = {
                ...data,
                transfer_with: null,
                account_id: currentAccount.id,
                category_id: (currentSubcategory.length === 0 && currentCategoryId) ? parseInt(currentCategoryId) : (currentSubcategory.length !== 0 ? currentSubcategory.category_id: null ),
                subcategory_id: currentSubcategory ? currentSubcategory.id : null
            }
            const response = await API_FUNCTIONS.addTransaction(request, token)
            if(response.data){
                const newTransaction = {
                    ...response.data.transaction,
                    category_id: response.data.transaction.category_id ? parseInt(response.data.transaction.category_id) : null,
                    transaction_type_id: parseInt(response.data.transaction.transaction_type_id)
                }
                handleAddTransaction(newTransaction)

                currentAccount.account_balance_upcoming = response.data.new_upcoming_balance
                document.getElementById('addTransactionForm').reset()
                TOASTS.success('La transaction a bien été créée.')
                if(mobileLimit >= window.innerWidth){
                    handleToggleTransactionForm()
                }
            }
        }catch(e){
            TOASTS.error('Oups, une erreur est survenue...')
        }
    }

    const [position, setPosition] = useState({ left: window.innerWidth/2, top: window.innerHeight-10});
    const [isDragging, setIsDragging] = useState(false);
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    // const [ isBorderHovered, setIsBorderHovered ] = useState('')

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setOffset({
            x: e.clientX - position.left,
            y: e.clientY - position.top
        });
    };
    
    const handleMouseMove = (e) => {
        const element = document.getElementById('addTransactionForm')
        let left=0, top=0
        let oldLeft = position.left, oldTop = position.top
        if(e.clientX-offset.x >= window.innerWidth - element.offsetWidth){
            left = window.innerWidth - element.offsetWidth
        }else if(e.clientX-offset.x < 0){
            left=0
        }else{
            left = e.clientX - offset.x
        }


        if(e.clientY - offset.y >= window.innerHeight /* - element.offsetHeight*/-10){
            top = window.innerHeight - 10/*element.offsetHeight*/
        }else if(e.clientY - offset.y < 0){
            top = 0
        }else{
            top = e.clientY - offset.y
        }
        
        setPosition({
            left: left,
            top: top,
            // right: position.right + (oldLeft-left),
            // bottom: position.bottom + (oldTop-top)
        });
        localStorage.setItem('formLeft', left)
        localStorage.setItem('formTop', top)
        
    };
    
    const handleMouseUp = () => {
        setIsDragging(false);
    };

    function preventTextSelection(event) {
        event.preventDefault();
    }
    const handleDragStart = (e) => {
        e.preventDefault();
    };
    
    const onResize = () => {
        let left = Number(localStorage.getItem('formLeft'))
        let top = Number(localStorage.getItem('formTop'))

        if(left > window.innerWidth - document.getElementById('addTransactionForm').offsetWidth){
            left = window.innerWidth - document.getElementById('addTransactionForm').offsetWidth
        }
        if(top > window.innerHeight-10){
            top = window.innerHeight-10
        }
        
        if(left < Number(localStorage.getItem('formLeft')) || top < Number(localStorage.getItem('formTop'))){
            setPosition({
                left: left,
                top: top
            })
            localStorage.setItem('formLeft', left)
            localStorage.setItem('formTop', top)
        }
    }

    useEffect(() => {
        localStorage.setItem('formLeft', position.left)
        localStorage.setItem('formTop', position.top)
    }, [position])

    
    useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, [])
    
    useEffect(() => {
        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
            window.addEventListener('selectstart', preventTextSelection);
            return () => {
                window.removeEventListener('selectstart', preventTextSelection);
                window.removeEventListener('mousemove', handleMouseMove);
                window.removeEventListener('mouseup', handleMouseUp);
            };
        }
    }, [isDragging]);


    return (
        <form 
            className='currentAccount__newLine' 
            onSubmit={handleSubmit(addTransaction)} 
            style={{ left: position.left, top:position.top}}
            onMouseDown={handleMouseDown}
            id="addTransactionForm"
        >
            <FontAwesomeIcon icon={faXmark} className='currentAccount__newLine__closeButton' onClick={() => handleToggleTransactionForm()}/>

            <h2 className='currentAccount__newLine__title'>Nouvelle transaction</h2>

            <input type="date" defaultValue={new Date().toISOString().split('T')[0]}{...register('transaction_date')} required />
            <select {...register('transaction_type_id')}>
                {transactionTypes.map((type, index) => 
                    <option value={type.id} key={index}>{type.transaction_type_name}</option>
                )}
            </select>
            <input type="text" {...register('transaction_counterparty')} placeholder='Tiers' required/>
            <input type="text" {...register('transaction_description')} placeholder='Description'/>

            <select onChange={(e) => e.target.value!=="" && handleSelectCategory(e.target.value)}>
                    <option value="">-- Catégorie --</option>
                    {categories.map((cat, index) => 
                        <option value={cat.id} key={index}>{cat.category_name} {(currentSubcategory.length !== 0 && currentSubcategory.category_id === cat.id) && '-> '+currentSubcategory.subcategory_name}</option>
                    )}
            </select>
            
            <input type="number" step='0.01' {...register('transaction_amount')} placeholder='Montant'/>

            <button type="submit">Créer</button>
        </form>
    )
}

export default NewTransactionForm