import axios from "axios";

// export const API_URL = "http://127.0.0.1:8000/api"
// export const API_URL = "https://api.cloudy-pocket.fr/api"
export const API_URL = process.env.REACT_APP_API_URL

// export const API_STORAGE = "http://127.0.0.1:8000/storage/uploads/"
// export const API_STORAGE = "https://api.cloudy-pocket.fr/storage/uploads/"
export const API_STORAGE = process.env.REACT_APP_API_STORAGE




const API_ROUTES={
    LOGIN: `${API_URL}/login`,
    LOGOUT: `${API_URL}/logout`,
    REGISTER: `${API_URL}/register`,
    CURRENT_USER: `${API_URL}/currentuser`,
    USERS: `${API_URL}/users`,
    THEMES: `${API_URL}/themes`,
    ACCOUNTS: `${API_URL}/accounts`,
    SHARE: `${API_URL}/share`,
    UNSHARE: `${API_URL}/unshare`,
    SHARED: `${API_URL}/shared`,
    ARCHIVE: `${API_URL}/archive`,
    UNARCHIVE: `${API_URL}/unarchive`,
    TRANSACTIONS: `${API_URL}/transactions`,
    CHECK: `${API_URL}/checktransactions`,
    UNCHECK: `${API_URL}/unchecktransactions`,
    TRANSACTION_TYPES: `${API_URL}/transactiontypes`,
    CATEGORIES: `${API_URL}/categories`,
    SUBCATEGORIES: `${API_URL}/subcategories`,
    CURRENCIES: `${API_URL}/currencies`,
    PAYMENT_PLAN: `${API_URL}/plans`,
    LOGOS: `${API_URL}/logos`,
    TICKETS: `${API_URL}/tickets`,
    CONTACTS: `${API_URL}/contacts`,
}

export const API_FUNCTIONS={

    // USERS
    login: async(data) => {
        try{
            const response = await axios.post(API_ROUTES.LOGIN, data)
            if(response.data.access_token.token){
                return response
            }
        }catch(e){
            return e
        }
    },
    currentUser: async(data)=>{
        
        try{
            const response = await axios.get(API_ROUTES.CURRENT_USER, {
                headers: {
                    Authorization: `Bearer ${data}`,
                  }
                })
            if(response.data){
                return response.data
            }
        }catch(e){
            return e
        }

    },
    logout: async(token)=>{
        try{
            const response = await axios.post(API_ROUTES.LOGOUT,null, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if(response){
                return response
            }
        }catch(e){
            console.error(e)
        }
    },
    allUsers: async(token)=>{
        try{
            const response = await axios.get(API_ROUTES.USERS, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            if(response)return response.data
        }catch(e){
            return e
        }
    },
    register: async(data)=>{
        try{
            return await axios.post(API_ROUTES.REGISTER, data)
        }catch(e){
            console.error(e)
        }
    },
    updateUser: async(user, token) => {

        try{
            const response = await axios.put(API_ROUTES.USERS+'/'+user.id, user, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            return response
        }catch(e){
            return e
        }
    },
    deleteUser: async(id, token) => {
        try{
            return await axios.delete(API_ROUTES.USERS+'/'+id, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },

    //THEMES
    allThemes: async()=>{
        try{
            const response = await axios.get(API_ROUTES.THEMES)
            return response
        }catch(e){
            return e
        }
    },
    getTheme: async(id) => {
        try{
            const response = await axios.get(API_ROUTES.THEMES+'/'+id)
            return response
        }catch(e){
            return e
        }
    },
    //Accounts 
    allAccounts: async(query, token)=>{
        try{
            return await axios.get(API_ROUTES.ACCOUNTS, {
                headers: {
                    Authorization: `Bearer ${token}` 
                },
                params: query
            })
        }catch(e){
            return e
        }
    },
    createAccount: async(request, token) => {
        try{
            return await axios.post(API_ROUTES.ACCOUNTS, request, {
                headers:{
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    updateAccount: async(request, id, token) => {
        try{
            return await axios.put(API_ROUTES.ACCOUNTS+'/'+id, request, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    share: async(email, id, token)=>{
        try{
            return axios.put(API_ROUTES.SHARE+'/'+id, email, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    unshare: async(email, id, token) => {
        try{
            return await axios.put(API_ROUTES.UNSHARE+'/'+id, email, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    shared: async(id, token) => {
        try{
            return await axios.get(API_ROUTES.SHARED+'/'+id, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    archive: async(id, token) => {
        try{
            return await axios.put(API_ROUTES.ARCHIVE+'/'+id,null, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    unarchive: async(id, token) => {
        try{
            return await axios.put(API_ROUTES.UNARCHIVE+'/'+id,null, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    deleteAccount: async(id, token) => {
        try{
            return await axios.delete(API_ROUTES.ACCOUNTS+'/'+id, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    // TRANSACTIONS
    allTransactions: async(query, token) => {
        try{
            return await axios.get(API_ROUTES.TRANSACTIONS, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: query
            })
        }catch(e){
            return e
        }
    },
    addTransaction: async(request, token)=>{
        try{
            return await axios.post(API_ROUTES.TRANSACTIONS, request, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    updateTransaction: async(id, request, token) => {
        try{
            return await axios.put(API_ROUTES.TRANSACTIONS+'/'+id, request, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    check: async(id, request, token) =>{
        try{
            return await axios.put(API_ROUTES.CHECK+'/'+id, request, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    unCheck: async(id, request, token) => {
        try{
            return await axios.put(API_ROUTES.UNCHECK+'/'+id, request, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },

    // TRANSACTION TYPES
    allTransactionTypes: async(token) => {
        try{
            return axios.get(API_ROUTES.TRANSACTION_TYPES, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    deleteTransaction: async(id, token)=>{
        try{
            return await axios.delete(API_ROUTES.TRANSACTIONS+'/'+id, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    // CATEGORIES
    allCategories: async(token)=>{
        try{
            return await axios.get(API_ROUTES.CATEGORIES, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },

    // SUBCATEGORIES
    allSubCategories: async(query, token) => {
        try{
            return await axios.get(API_ROUTES.SUBCATEGORIES, {
                headers: {
                    Authorization: `Bearer ${token}`
                },params: query
            })
        }catch(e){
            return e
        }
    },
    createSubcategory: async(request, token) => {
        try{
            return await axios.post(API_ROUTES.SUBCATEGORIES, request, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    updateSubcategory: async(request, token) => {
        try{
            return await axios.put(API_ROUTES.SUBCATEGORIES+'/'+request.id, request, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    deleteSubcategory: async(request, id, token)=>{
        try{
            return await axios.delete(API_ROUTES.SUBCATEGORIES+'/'+id, {
                headers: {
                    Authorization: `Bearer ${token}`
                }, params: request
            })
        }catch(e){
            return e
        }
    },

    // CURRENCIES

    allCurrencies: async(token) => {
        try{
            return await axios.get(API_ROUTES.CURRENCIES, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },

    // PAYMENT PLAN

    allPlans: async(token) => {
        try{
            return await axios.get(API_ROUTES.PAYMENT_PLAN, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    createPlan: async(request, token) => {

        try{
            return await axios.post(API_ROUTES.PAYMENT_PLAN, request, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    updatePlan: async(request, token) => {
        try{
            return await axios.put(API_ROUTES.PAYMENT_PLAN+'/'+request.id, request, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    deletePlan: async(id, token) => {
        try{
            return await axios.delete(API_ROUTES.PAYMENT_PLAN+'/'+id, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },

    // LOGOS

    allLogos: async(token) => {
        try{
            return await axios.get(API_ROUTES.LOGOS, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    oneLogo: async(account_id, token) => {
        try{
            return await axios.get(API_ROUTES.LOGOS+'/'+account_id, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    addLogo: async(data, token) => {
        try{
            return await axios.post(API_ROUTES.LOGOS, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    deleteLogo: async(id, token) => {
        try{
            return await axios.delete(API_ROUTES.LOGOS+'/'+id, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },

    // TICKETS

    allTickets: async(token) => {
        try{
            return await axios.get(API_ROUTES.TICKETS, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    addTicket: async(ticket, token) => {
        try{
            return await axios.post(API_ROUTES.TICKETS, ticket, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    deleteTicket: async(id, token) => {
        try{
            return await axios.delete(API_ROUTES.TICKETS+'/'+id, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        }catch(e){
            return e
        }
    },
    // Email Contacts
    sendMessage: async($request) => {
        try {
            return await axios.post(API_ROUTES.CONTACTS, $request)
        }catch(e){
            return e
        }
    }

}