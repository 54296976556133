import React from 'react'

function DBHome() {
  return (
    <div>
        Mon tableau de bord
    </div>
  )
}

export default DBHome