import React, {useContext, useEffect, useState} from 'react'

import { GlobalContext } from '../../contexts/GlobalContext'
import { DashboardContext } from '../../contexts/DashboardContext'
import { API_FUNCTIONS } from '../../utils/apiFunctions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import NewAccountModal from '../modals/NewAccountModal'
import { openContent } from '../../utils/functions'
import Spinner from '../Spinner'

function DBAccounts() {
    const {user, token } = useContext(GlobalContext)
    const {setDbUrl, setCurrentAccount, accounts, setAccounts} = useContext(DashboardContext)
    
    const [isLoaded, setIsLoaded] = useState(false)
    const loadingAccounts = async()=>{
        try{
            const query= {
                accounts: "active"
            }
            const response = await API_FUNCTIONS.allAccounts(query, token)
            response.data && setAccounts(response.data.data)
        }catch(e){
            console.error(e)
        }finally{
            setIsLoaded(true)
        }
    }

    useEffect(() => {
        loadingAccounts()
    }, [])

    const chooseAccount = (account) => {
        setCurrentAccount(account)
        setDbUrl('dbOneAccount')
        localStorage.setItem('currentAccount', account.id)
    }

    const updateAccountsList = (newAccount) => {
        setAccounts(prev => [...prev, newAccount])
    }
    

  return (
    <article className='accounts'>
        <h2 className='accounts__title'>Mes comptes</h2>
        <div className='accounts__container'>
            {
                isLoaded ? 
                    accounts.map((account, index) =>
                        account.account_owner === user.id && <div key={index} className='accounts__container__item' onClick={() => chooseAccount(account)}>
                            {account.account_name}
                        </div>
                    )
                :
                    <Spinner/>
            }
            {isLoaded && <div className='accounts__container__item' onClick={() => openContent('newAccountModal')}>
                <FontAwesomeIcon icon={faPlus} className='accounts__container__item__plus'/>
            </div>
            }
        </div>


        { accounts.filter(item => item.account_owner !== user.id).count > 0 && <h2>Partagé avec moi</h2>}
        <div className='accounts__container'>
            
            {
                isLoaded &&
                    accounts.map((account, index) =>
                        account.account_owner !== user.id && <div key={index} className='accounts__container__item' onClick={() => chooseAccount(account)}>
                            {account.account_name}
                        </div>
                    )
            }
        </div>

        

        <NewAccountModal updateAccountsList={updateAccountsList}/>
    </article>
  )
}

export default DBAccounts