import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { faPen, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { clickOutModal, closeContent, listenEscapeKey } from '../../utils/functions'
import { GlobalContext } from '../../contexts/GlobalContext'
import { DashboardContext } from '../../contexts/DashboardContext'
import { API_FUNCTIONS } from '../../utils/apiFunctions'
import { TOASTS } from '../../utils/toasts'

function PaymentPlanModal() {
    const { token } = useContext(GlobalContext)
    const { accounts, transactionTypes, categories, currentAccount, currencies } = useContext(DashboardContext)
    const { register, handleSubmit, setValue, watch, formState: {errors}} = useForm()

    let tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    tomorrow = tomorrow.toISOString().split('T')[0]

    const firstDatePlan = watch('plan_first_date')
    useEffect(() => {
        let next = new Date()
        if(firstDatePlan){
            const current = new Date(firstDatePlan)
            next.setDate(current.getDate() +1 )
        }else{
            next.setDate(next.getDate() + 2)
        }
        next = next.toISOString().split('T')[0]
        setMinEndDate(next)
        
        if(new Date(next) > new Date(watch('plan_last_date'))){

            setValue('plan_last_date', next);
        }
         
    }, [firstDatePlan])
    
    
    
    const [ isLoaded, setIsLoaded ] = useState(false)
    const [ choice, setChoice ] = useState('')
    const [ plans, setPlans ] = useState([])
    const [ currentPlan, setCurrentPlan ] = useState({})
    const [ subcategories, setSubcategories ] = useState([])
    const [ ends, setEnds ] = useState('occurrence') // can be 'date' or 'occurrence'
    
    const [ selectedCategory, setSelectedCategory ] =useState('')
    const [ selectedSubcategory, setSelectedSubcategory ] = useState('')
    const [ selectedAccount, setSelectedAccount ] = useState(currentAccount.id)
    const [ transferWith, setTransferWith ] = useState('')
    const [ selectedTransactionType, setSelectedTransactionType ] = useState('')
    const [ currentCounterparty, setCurrentCounterparty ] = useState('')
    const [ currentDescription, setCurrentDescription ] = useState('')
    const [ currentAmount, setCurrentAmount ] = useState(0)
    const [ minEndDate, setMinEndDate ] = useState(new Date())
    
    

    const loadingPlans = async() => {
        try{
            const response = await API_FUNCTIONS.allPlans(token)
            if(response.status === 200){
                setPlans(response.data)
            }else{
                TOASTS.error('Oups, une erreur est survenue lors du chargement des échéanciers')
            }
        }catch(e){
            console.error(e);
            TOASTS.error('Oups, une erreur est survenue lors du chargement des échéanciers')
        }finally{
            setIsLoaded(true)
        }
    }
    useEffect(() => {
        loadingPlans()
    }, [])
    

    const createPlan = async(data) => {

        const request = {
            ...data,
            plan_account_id: selectedAccount,
            category_id: selectedCategory,
            subcategory_id: selectedSubcategory,
            plan_recurrence_number: data.plan_recurrence_number,
            plan_occurrence_number: ends === 'occurrence' ? data.plan_occurrence_number : null,
            plan_last_date: ends === 'date' ? data.plan_last_date : null,
        }

        try{
            const response = await API_FUNCTIONS.createPlan(request, token)
            if(response.data?.code === 201){
                const plan = {
                    ...response.data.data,
                    transaction_type_id: Number(response.data.data.transaction_type_id)
                }
                setPlans(prev => [...prev, plan])
                document.getElementById('newPlanForm')
                setChoice('')
                TOASTS.success("L'échéancier a bien été créé")
            }else{
                TOASTS.error("Oups, une erreur est survenue lors de la création de l'échéancier...")
            }
        }catch(e){
            console.error(e);
            TOASTS.error("Oups, une erreur est survenue lors de la création de l'échéancier...")
        }
    }

    

    const updatePlan = async(e) => {
        e.preventDefault()
        try{
            const request = {
                ...currentPlan,
                plan_transaction_type_id: selectedTransactionType,
                category_id: selectedCategory,
                subcategory_id: selectedSubcategory,
                plan_amount: currentAmount,
                plan_counterparty: currentCounterparty,
                plan_description: currentDescription,
                plan_transfer_with: transferWith
            }
            const response = await API_FUNCTIONS.updatePlan(request, token)
            if(response.data?.code === 200){
                const updatedPlans = plans.map(p => 
                    p.id === currentPlan.id ? 
                        response.data.data
                    :
                        p    
                )
                setPlans(updatedPlans)
                setChoice('')
                setCurrentPlan([])
                setSelectedCategory('')
                setSelectedSubcategory('')
                setSelectedTransactionType('')
                setTransferWith('')
                setCurrentDescription('')
                setCurrentCounterparty('')
                setCurrentAmount('')
                TOASTS.success("L'échéancier a bien été mis à jour")
            }else{
                TOASTS.error("Oups, une erreur est survenue lors de la mise à jour de l'échéancier...")
            }
        }catch(e){
            TOASTS.error("Oups, une erreur est survenue lors de la mise à jour de l'échéancier...")
            console.error(e);
        }
    }

    const deletePlan = async(id) => {
        try{
            const response = await API_FUNCTIONS.deletePlan(id, token)
            if(response.data?.code === 200){
                setPlans(prev => prev.filter(item => item.id !== id))
                TOASTS.success("L'échéancier a bien été supprimé")
            }else{
                TOASTS.error("Oups, une erreur est survenue lors de la suppression de l'échéancier...")
            }
        }catch(e){
            TOASTS.error("Oups, une erreur est survenue lors de la suppression de l'échéancier...")
            console.error(e);
        }
    }



    const getSubcategories = async()=>{
        try{
            const response = await API_FUNCTIONS.allSubCategories({account_id: selectedAccount}, token)
            if(response.data?.code === 200){
                setSubcategories(response.data.data)
            }
        }catch(e){
            console.error(e);
        }
    }
    useEffect(() => {
        if(selectedAccount.length !== 0){
            getSubcategories()
        }
    }, [selectedAccount])
    
    
    useEffect(() => {
        if(Object.keys(currentPlan).length > 0){
            setSelectedTransactionType(currentPlan.plan_transaction_type_id)
            setSelectedCategory(currentPlan.category_id)
            setSelectedSubcategory(currentPlan.subcategory_id)
            setCurrentAmount(currentPlan.plan_amount)
            setCurrentCounterparty(currentPlan.plan_counterparty)
            setCurrentDescription(currentPlan.plan_description)
            setTransferWith(currentPlan.plan_transfer_with)
        }
    }, [currentPlan])

    
    
    
    

    listenEscapeKey('paymentPlanModal')
    return (
        <div className='v2modal paymentPlanModal' id='paymentPlanModal' onClick={(e) => clickOutModal(e, 'paymentPlanModal')}>
            <div className='v2modal__content paymentPlanModal__content'> 
                <FontAwesomeIcon icon={faXmark} alt="fermer" onClick={() => closeContent('paymentPlanModal')}  className='v2modal__closeButton'/>
                <h2 className=''>Échéanciers</h2>
    
                { choice === '' && <div onClick={() => {setChoice('create')}} className='paymentPlanModal__button paymentPlanModal__button--openForm'>Créer un échéancier</div>}

                {(isLoaded && choice === '') ?
                    plans.length > 0 ? 
                        <table className='paymentPlanModal__table'>
                            <thead>
                                <tr>
                                    <th>Compte</th>
                                    <th>Intervalle</th>
                                    <th>Tiers</th>
                                    <th>Montant</th>
                                    <th>Prochaine échéance</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {plans.map(plan => 
                                    <tr key={plan.id} >
                                        <td>{accounts.find(item => item.id === plan.plan_account_id)?.account_name}</td>
                                        <td>Tous les {plan.plan_recurrence_number} {plan.plan_recurrence_type === 'day' ? "jours" : plan.plan_recurrence_type === 'month' ? "mois" : "ans"}</td>
                                        <td>{plan.plan_counterparty}</td>
                                        <td className={transactionTypes.find(item => item.id === plan.transaction_type_id).transaction_type_is_debit ? "danger" : "success"}>{transactionTypes.find(item => item.id === plan.transaction_type_id).transaction_type_is_debit && "-"}{plan.plan_amount}{accounts.find(item => item.id === plan.plan_account_id).currency.currency_symbol}</td>
                                        <td>{plan.plan_next_date}</td>
                                        <td>
                                            <FontAwesomeIcon icon={faPen} className='currentAccount__table__settings__menu__button' onClick={() => {setCurrentPlan(plan); setChoice('update')}}/>
                                            <FontAwesomeIcon icon={faTrash} className='danger currentAccount__table__settings__menu__button' onClick={() => deletePlan(plan.id)}/>
                                        </td>
                                    </tr>
                                )}

                            </tbody>

                        </table>
                        
                    : 
                        <p>Il n'y a pas encore d'échéancier</p>
                :
                null
                }

                {
                    choice === 'create' &&
                    <form onSubmit={handleSubmit(createPlan)} id='newPlanForm' className='paymentPlanModal__addForm'>
                        <div onClick={() => setChoice('')} className='paymentPlanModal__button paymentPlanModal__button--exitForm'>Annuler</div>
                        <h3>Nouvel échancier</h3>

                        <label>Choisir un compte bancaire</label>
                        <select value={selectedAccount} onChange={e => {setSelectedAccount(e.target.value); setSelectedCategory(''); setSelectedSubcategory(''); setTransferWith('')}}>
                            {
                                accounts.map(acc => 
                                    <option value={acc.id} key={acc.id}>{acc.account_name} ({acc.owner})</option>
                                )
                            }
                        </select>

                        <label>Première échéance</label>
                        <input type="date" defaultValue={tomorrow} min={tomorrow} 
                            {...register('plan_first_date', {
                            validate: value => Number(value.split('-')[2]) <= 25 || 'Le jour de la date ne peut pas dépasser 25'
                            
                        })
                            }/>
                            {errors.plan_first_date && <p className='danger'>{errors.plan_first_date.message}</p>}

                        <label>Récurrence</label>
                        <input type='number' min='1' step='1' defaultValue={1} {...register('plan_recurrence_number')}/>
                        <select {...register('plan_recurrence_type')} defaultValue={'month'}>
                            <option value="day">Jour(s)</option>
                            <option value="month">Mois</option>
                            <option value="year">An(s)</option>
                        </select>

                        <div className='paymentPlanModal__addForm__endPlan'>
                            <p>Se termine après :</p> 
                        
                            <div className='paymentPlanModal__addForm__endPlan__choose'>
                                X occurrences
                                <div onClick={(e) => {e.preventDefault(); setEnds(ends === 'date' ? "occurrence" : "date")}} className='paymentPlanModal__addForm__endPlan__choose__toggle'>
                                    <div style={{left: ends !== 'date' ? '0': ''}}></div>
                                </div>
                                Une date
                            </div>


                            {
                            ends === 'date' ? 
                                <div>
                                    <label>Date de fin</label>
                                    <input type="date" defaultValue={minEndDate} {...register('plan_last_date')} min={minEndDate} /> 
                                </div>
                            :
                                <div>
                                    <label>Se termine après X occurences</label>
                                    <input type="number" defaultValue="1" min="1" step="1" {...register('plan_occurrence_number')}/>
                                </div>  
                            }
                        </div>

                        <select {...register('transaction_type_id')}>
                            {transactionTypes.map(type => 
                                <option value={type.id} key={type.id}>{type.transaction_type_name}</option>    
                            )}
                        </select>

                        <select value={selectedCategory} onChange={e => setSelectedCategory(e.target.value)}>
                            <option value=''>-- Catégorie --</option>
                            {categories.map(cat => 
                                <option value={cat.id} key={cat.id}>{cat.category_name}</option>    
                            )}
                        </select>

                        {
                            (selectedAccount !== '' && subcategories.length !== 0 && selectedCategory !== '') &&
                            <select value={selectedSubcategory} onChange={e => setSelectedSubcategory(e.target.value)}>
                                <option value=''>-- Sous-catégorie --</option>
                                {subcategories?.filter(item => item.category_id === Number(selectedCategory)).map(sub => 
                                    <option value={sub.id} key={sub.id}>{sub.subcategory_name}</option>
                                )}
                            </select>
                        }
                        
                        
                        <div>
                            <label>Cet échancier est un transfert ?</label>
                            <select value={transferWith} onChange={e => setTransferWith(e.target.value)}>
                                <option value=''>-- Transfert vers --</option>
                                {
                                    accounts.filter(item => item.id !== selectedAccount).map(acc => 
                                        <option value={acc.id} key={acc.id}>{acc.account_name} ({acc.owner})</option>
                                    )
                                }
                            </select>
                            
                            <label>Tiers</label>
                            <input type="test" {...register('plan_counterparty')}/>

                        </div>

                        <label>Description</label>
                        <input type="text" {...register('plan_description')}/>

                        <label>Montant</label>
                        <input type="number" defaultValue='0' step='0.01' {...register('plan_amount')}/>

                        <button type="submit">Enregistrer</button>

                    </form>
                }


                {
                    choice === 'update' &&
                    <form onSubmit={(e) => updatePlan(e)} id='updatePlanForm' className='paymentPlanModal__updateForm'>
                        <div onClick={() => {setCurrentPlan([]);setChoice('')}} className='paymentPlanModal__button paymentPlanModal__button--exitForm'>Annuler</div>
                        <h3>Modifier l'échancier</h3>

                        <p>Compte : {accounts.find(item => item.id === currentPlan.plan_account_id).account_name}</p>

                        <p>Prochaine échéance : {currentPlan.plan_next_date}</p>
                        <p>Intervalle : Tous les {currentPlan.plan_recurrence_number} {currentPlan.plan_recurrence_type === 'day' ? "jours" : currentPlan.plan_recurrence_type === 'month' ? "mois" : "ans"}</p>

                        

                        <select value={selectedTransactionType} onChange={e => setSelectedTransactionType(e.target.value)}>
                            {transactionTypes.map(type => 
                                <option value={type.id} key={type.id}>{type.transaction_type_name}</option>    
                            )}
                        </select>

                        <select value={selectedCategory || ''} onChange={e => setSelectedCategory(e.target.value)} >
                            <option value=''>-- Catégorie --</option>
                            {categories.map(cat => 
                                <option value={cat.id} key={cat.id}>{cat.category_name}</option>    
                            )}
                        </select>

                        {
                            (subcategories.length !== 0 && selectedCategory) &&
                            <select value={selectedSubcategory} onChange={e => setSelectedSubcategory(e.target.value)}>
                                <option value=''>-- Sous-catégorie --</option>
                                {subcategories?.filter(item => item.category_id === Number(selectedCategory)).map(sub => 
                                    <option value={sub.id} key={sub.id}>{sub.subcategory_name}</option>
                                )}
                            </select>
                        }
                  

                        {currentPlan.plan_transfer_with ? 
                            <select value={transferWith} onChange={e => setTransferWith(e.target.value)}>
                                <option value=''>-- Transfert vers --</option>
                                {
                                    accounts.filter(item => item.id !== selectedAccount).map(acc => 
                                        <option value={acc.id} key={acc.id}>{acc.account_name} ({acc.owner})</option>
                                    )
                                }
                            </select>
                        :
                            (
                                <label>Tiers</label>,
                                <input type="test" value={currentCounterparty} onChange={e => setCurrentCounterparty(e.target.value)}/>
                            )
                        }
  

                        <label>Description</label>
                        <input type="text" value={currentDescription} onChange={e => setCurrentDescription(e.target.value)}/>

                        <label>Montant</label>
                        <input type="number" value={currentAmount} step='0.01' onChange={e => setCurrentAmount(e.target.value)}/>

                        <button type="submit">Enregistrer</button>

                    </form>
                }
                
            </div>
        </div>
    )
}

export default PaymentPlanModal