import React, { useEffect } from 'react'
import { autoScroll } from '../utils/functions'

function Error() {

  useEffect(() => {
    autoScroll()
  }, [])

  return (
    <div className='mainContainer'>
      Error
    </div>
  )
}

export default Error