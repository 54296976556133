import React, {useContext, useEffect, useState} from 'react'
import { useForm } from "react-hook-form"
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { GlobalContext } from '../../contexts/GlobalContext'
import { clickOutModal, closeContent, listenEscapeKey } from '../../utils/functions'
import { API_FUNCTIONS } from '../../utils/apiFunctions'
import { TOASTS } from '../../utils/toasts'

function NewAccountModal({updateAccountsList}) {
    const { token, user } = useContext(GlobalContext)

    const {register, handleSubmit, formState: {errors}} = useForm()

    const [currencies, setCurrencies ] = useState([])
    const [ isLoaded, setIsLoaded ] = useState(false)

    /**
     * LOADING DATAS
     */

    const getCurrencies = async()=>{
        try{
            const response = await API_FUNCTIONS.allCurrencies(token)
            if(response.data?.code === 200){
                setCurrencies(response.data.data)
            }else{
                TOASTS.error('Oups, une erreur est survenue lors du chargement des devises')
            }
        }catch(e){
            console.error(e);
            TOASTS.error('Oups, une erreur est survenue lors du chargement des devises')
        }finally{
            setIsLoaded(true)
        }
    }

    useEffect(() => {
        getCurrencies()
    }, [])

    /**
     * API FUNCTIONS
     */


    const createAccount = async(data) => {
        
        try{
            const request = {
                ...data,
                currency_id: data.currency_id || 1
            }
            const response = await API_FUNCTIONS.createAccount(request, token)

            if(response.status === 200 && response.data.code === 201){
                const newAccount = {
                    ...response.data.account,
                    currency: currencies.find(item => item.id === Number(response.data.account.currency_id)),
                    account_is_archive: 0,
                    pivot: {
                        account_id: response.data.account.id,
                        user_id: user.id
                    },
                    owner: user.firstname+' '+user.lastname[0]+'.',
                }
                TOASTS.success('Votre nouveau compte a bien été créé')
                updateAccountsList(newAccount)
                document.getElementById('newAccountForm').reset()
                closeContent('newAccountModal')
            }else{
                TOASTS.error('Oups, une erreur est survenue lors de la création de votre compte')
            }
        }catch(e){
            console.error(e);
            TOASTS.error('Oups, une erreur est survenue lors de la création de votre compte')
        }
    }
    

    return (
        <div className='modal' id='newAccountModal' onClick={(e) => clickOutModal(e, 'newAccountModal')}>
            <div className='modal__content'> 
                <FontAwesomeIcon icon={faXmark} alt="fermer" onClick={() => closeContent('newAccountModal')}  className='modal__closeButton'/>
                <h2 className='success'>Nouveau compte</h2>

                {isLoaded && 
                    <form onSubmit={handleSubmit(createAccount)} id='newAccountForm'>
                        <label>Nom du compte</label>
                        <input type="text" {...register('account_name', {required: "Nom de compte obligatoire"})}/>
                        {errors.account_name && <p className='danger'>{errors.account_name.message}</p>}

                        <label>Choisissez la devise</label>
                        <select {...register('currency_id')}>
                            {
                                currencies.map(currency => 
                                        <option value={currency.id} key={currency.id}>{currency.currency_name} ({currency.currency_symbol})</option>
                                    ) 
                            }
                        </select>

                        <label>Solde initial du compte</label>
                        <input type='number' {...register('initial_balance')} defaultValue="0" step='0.01'/>
                        <button type="submit">Créer</button>
                    </form>
                }   

            </div>
        </div>
    )
}

export default NewAccountModal