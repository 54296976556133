import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { clickOutModal, closeContent, listenEscapeKey } from '../../utils/functions'
import { GlobalContext } from '../../contexts/GlobalContext'
import { DashboardContext } from '../../contexts/DashboardContext'
import { API_FUNCTIONS } from '../../utils/apiFunctions'
import { TOASTS } from '../../utils/toasts'

function NewTransferModal({currentAccount, handleUpdateTransactions, subcategories}) {
    const { token } = useContext(GlobalContext)
    const { categories, accounts, transactionTypes } = useContext(DashboardContext)

    const {register, handleSubmit, formState: {errors}} = useForm()

    const [currentCategory, setCurrentCategory] = useState('')
    const [currentSubcategory, setCurrentSubcategory] = useState('')


    const createTransfer = async (data) => {
        try{
            const request = {
                ...data,
                category_id: Number(currentCategory) || null,
                subcategory_id: currentCategory ? Number(currentSubcategory) || null : null,
                account_id: currentAccount.id
            }
            const response = await API_FUNCTIONS.addTransaction(request, token)
            console.log('create transfer', response);
            if(response.data.code === 201){
                const newTransaction = response.data.transaction
                newTransaction.transaction_type_id = parseInt(newTransaction.transaction_type_id)
                handleUpdateTransactions(newTransaction)
                document.getElementById('newTransactionForm').reset()
                closeContent('newTransferModal')
                TOASTS.success('Le transfert a bien été créé')
            }else{
                TOASTS.error('Oups, une erreur est survenue lors de la création du transfert...')
            }
        }catch(e){
            console.error(e);
            TOASTS.error('Oups, une erreur est survenue lors de la création du transfert...')
        }
    }

    listenEscapeKey('newTransferModal')
    return (
        <div className='v2modal newTransferModal' id='newTransferModal' onClick={(e) => clickOutModal(e, 'newTransferModal')}>
            <div className='v2modal__content newTransferModal__content'> 
                <FontAwesomeIcon icon={faXmark} alt="fermer" onClick={() => closeContent('newTransferModal')}  className='v2modal__closeButton'/>
                <h2 className=''>Créer un transfert entre deux comptes</h2>
                
                <form onSubmit={handleSubmit(createTransfer)} id="newTransactionForm" className='newTransferModal__form'>
                    <select {...register('transfer_with')}>
                        {
                            accounts.filter(item => item.id !== currentAccount.id).map(acc =>
                                <option value={acc.id} key={acc.id}>{acc.account_name} ({acc.owner})</option>    
                            )
                        }
                    </select>
                    <input type="date" {...register('transaction_date')}/>

                    <select {...register('transaction_type_id')}>
                        {
                            transactionTypes.map(tr => 
                                <option value={tr.id} key={tr.id}>{tr.transaction_type_name}</option>
                            )
                        }
                    </select>

                    <label>Description</label>
                    <input type='text' {...register('transaction_description')} />

                    <select value={currentCategory} onChange={(e) => {setCurrentCategory(e.target.value); setCurrentSubcategory('')}} >
                        <option value=''>-- Catégorie --</option>
                        {
                            categories.map(cat =>
                                <option value={cat.id} key={cat.id}>{cat.category_name}</option>
                            )
                        }
                    </select>

                    {currentCategory !== '' && subcategories.filter(item => item.category_id === Number(currentCategory)).length !== 0 && 
                        <select value={currentSubcategory} onChange={(e) => setCurrentSubcategory(e.target.value)}>
                            <option value=''>-- Sous-catégorie --</option>
                            {
                                subcategories.map(sub => 
                                    sub.category_id === Number(currentCategory) && <option value={sub.id} key={sub.id}>{sub.subcategory_name}</option>    
                                )
                            }
                        </select>
                    }

                    <label>Montant de la transaction</label>
                    <input type='number' defaultValue='0' step='0.01' {...register('transaction_amount')} />

                    <button type='submit'>Créer le transfert</button>
                </form>                

                
            </div>
        </div>
    )
}

export default NewTransferModal