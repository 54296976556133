import React, { useContext, useEffect } from 'react'
import { DashboardContext } from '../../contexts/DashboardContext'
import { GlobalContext } from '../../contexts/GlobalContext'
import { TOASTS } from '../../utils/toasts'

function DBAdmin() {
    const {user} = useContext(GlobalContext)
    const {dbUrl, setDbUrl} = useContext(DashboardContext)

    useEffect(() => {
        if(user.role_id && user.role_id !== 3){
            TOASTS.error('Accès non autorisé')
            setDbUrl('dbAccounts')
        }
    }, [user])


  return (
    <div className='admin'>
      <h2 className='admin__title'>Statistiques actuelles</h2>
      <h2 className='admin__title'>Gestion des utilisateurs</h2>
    </div>
  )
}

export default DBAdmin