import React, {useContext, useEffect} from 'react'
import { useLocation } from 'react-router-dom'

import { GlobalContext } from '../contexts/GlobalContext'

import Bubble from '../components/Bubble'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloud } from '@fortawesome/free-solid-svg-icons'
import { autoScroll } from '../utils/functions'


function Home() {
  const {setUrl} = useContext(GlobalContext)

  setUrl(useLocation().pathname)

  useEffect(() => {
    autoScroll()
  }, [])

  return (
    <main className='mainContainer'>
      

      <div className='containerTest'>
        <div>
          <h1>✨ L'application arrive bientôt ! ✨</h1>
          <p>Rendez-vous le <strong>03 novembre 2024</strong> pour la <strong>sortie officielle</strong> de <strong>Cloudy Pocket</strong> !</p><br/>
        </div>
        <Bubble 
        text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eu semper erat, et sodales tortor. Praesent mauris diam, efficitur pharetra orci vel, facilisis egestas magna. Proin sed fermentum lorem. Proin accumsan nulla sed enim rutrum dignissim. Integer tempus lacus eget nibh viverra dictum. Aliquam sed imperdiet turpis. Aenean cursus est id tellus elementum, sit amet vulputate leo iaculis. Praesent placerat, tellus ac maximus consequat, tellus urna fringilla odio, at tristique nisi lectus vitae odio. Praesent quis facilisis purus. Etiam id metus sit amet ex pretium sagittis accumsan non sapien. Proin ac laoreet nibh."
        position="right"
        >
          Des comptes clairs, une vie plus légère! Découvrez comment notre appli peut transformer votre rapport à l'argent.
        </Bubble>
        <FontAwesomeIcon icon={faCloud} className='cloudyIcon--right'/>
        


        <Bubble 
        position="left"
        >
          Adieu les fins de mois difficiles! Suivez vos dépenses et boostez votre épargne avec <b>Cloudy Pocket</b>.
        </Bubble>
        {/* <svg 
          className='cloudyIcon--left'
          xmlns="http://www.w3.org/2000/svg" 
          viewBox="0 0 640 512"
        >
          <path 
            d="M0 336c0 79.5 64.5 144 144 144H512c70.7 0 128-57.3 128-128c0-61.9-44-113.6-102.4-125.4c4.1-10.7 6.4-22.4 6.4-34.6c0-53-43-96-96-96c-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32C167.6 32 96 103.6 96 192c0 2.7 .1 5.4 .2 8.1C40.2 219.8 0 273.2 0 336z"
          />
        </svg> */}
        <svg className='cloudyIcon--left' viewBox="0 0 200 140" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M165.666 59.918L164.608 62.6777L167.505 63.263C184.61 66.7192 197.5 81.8679 197.5 100C197.5 120.713 180.713 137.5 160 137.5H45C21.537 137.5 2.5 118.463 2.5 95C2.5 76.4689 14.3614 60.7048 30.8921 54.8896L32.6289 54.2786L32.5608 52.4387C32.5293 51.5872 32.5 50.7897 32.5 50C32.5 23.7557 53.7557 2.5 80 2.5C97.6013 2.5 112.95 12.0536 121.178 26.3121L122.516 28.629L124.737 27.1384C129.089 24.2181 134.356 22.5 140 22.5C155.182 22.5 167.5 34.8182 167.5 50C167.5 53.4974 166.841 56.8509 165.666 59.918Z"/>
        </svg>





        <Bubble 
        position="right"
        >
          Prenez le contrôle de votre argent dès aujourd'hui! Gérez vos dépenses et vos revenus facilement.
        </Bubble>
        <FontAwesomeIcon icon={faCloud} className='cloudyIcon--right'/>

      </div>

    
    
    </main>
  )
}

export default Home