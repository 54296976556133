import React, {useContext, useState} from 'react'
import { useForm } from 'react-hook-form'

import { closeContent, clickOutModal, listenEscapeKey } from '../../utils/functions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import { GlobalContext } from '../../contexts/GlobalContext'
import { DashboardContext } from '../../contexts/DashboardContext'
import { API_FUNCTIONS } from '../../utils/apiFunctions'
import { TOASTS } from '../../utils/toasts'

function ShareAccountModal({account_id}) {
    const {token} = useContext(GlobalContext)
    const {sharedWith, setSharedWith} = useContext(DashboardContext)
    const {register, reset,  handleSubmit} = useForm()

    const shareWith = async(data) => {
        try{
            const response = await API_FUNCTIONS.share(data, account_id, token)
            if(response.data.code === 200){
                TOASTS.success('Votre compte a bien été partagé')
                reset()
                setSharedWith(prev => [...prev, response.data.user])
                closeContent('shareAccountModal')
            }else if(response.data.code === 404){
                TOASTS.error("Cet utilisateur n'existe pas")
            }else if(response.data.code === 409){
                TOASTS.error('Ce compte est déjà partagé avec cet utilisateur...')
            }
        }catch(e){
            console.error(e);
            TOASTS.error('Oups, une erreur est survenue...')
        }
    }

    listenEscapeKey('selectSubcategoryModal')
    return (
        <div className='v2modal' id='shareAccountModal' onClick={(e) => clickOutModal(e, 'shareAccountModal')}>
            <div className='v2modal__content'> 
                <FontAwesomeIcon icon={faXmark} alt="fermer" onClick={() => closeContent('shareAccountModal')}  className='v2modal__closeButton'/>

                <h2>Partager ce compte</h2>

                <form onSubmit={handleSubmit(shareWith)}>
                    <label>Entrez l'adresse e-mail de l'utilisateur avec qui partager le compte</label>
                    <input type="email" {...register('email')}/>

                    <button type='submit'>Partager</button>
                </form>
            </div>
        </div>
    )
}

export default ShareAccountModal