import React, {useContext, useState} from 'react'

import { closeContent, clickOutModal, listenEscapeKey } from '../../utils/functions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'


function SelectSubcategoryModal({categoryId, subcategories, handleSelectSubcategory}) {


    const [selectedSub, setSelectedSub] = useState([])

    listenEscapeKey('selectSubcategoryModal')

    return (
        <div className='modal selectSubcategoryModal' id='selectSubcategoryModal' onClick={(e) => clickOutModal(e, 'selectSubcategoryModal')}>
            <div className='modal__content selectSubcategoryModal__content'> 
                <FontAwesomeIcon icon={faXmark} alt="fermer" onClick={() => closeContent('selectSubcategoryModal')}  className='modal__closeButton'/>

                {/* <div> */}
                    <p className='selectSubcategoryModal__content__title'>Ajouter une sous-catégorie ? </p>
                    <div className='selectSubcategoryModal__content__list'>
                        <div onClick={() => setSelectedSub([])} className={selectedSub.length === 0 ? 'success' : null}>
                            Pas de sous-catégorie
                        </div>
                        {subcategories.filter(item => parseInt(item.category_id) === parseInt(categoryId)).map((sub, index) => 
                            <div onClick={() => setSelectedSub(sub)} className={selectedSub.id === sub.id ? 'success' : null} key={index}>
                                {sub.subcategory_name}
                            </div>
                        )}
                    </div>
                    <button onClick={() => (handleSelectSubcategory(selectedSub), closeContent('selectSubcategoryModal'))}>Valider</button>
                {/* </div> */}
            </div>
        </div>
    )
}

export default SelectSubcategoryModal