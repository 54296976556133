import React, {useContext, useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { DashboardContext } from '../../contexts/DashboardContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera, faCaretLeft, faCloud, faEnvelope, faGear, /*faHouse,*/ faPiggyBank, faUserTie, faXmark } from '@fortawesome/free-solid-svg-icons'
import { isTokenExpired } from '../../utils/functions'
import { GlobalContext } from '../../contexts/GlobalContext'
import { TOASTS } from '../../utils/toasts'

import { changeMode } from '../../utils/functions'

function DBMenu() {
  const navigate = useNavigate()
  const { token, setToken, mode, setMode, user } = useContext(GlobalContext)
  const {setDbUrl, dbUrl} = useContext(DashboardContext)
  const [currentDbUrl, setCurrentDbUrl] = useState('')
  const [ismenuOpened, setIsMenuOpened] = useState(true)

  const toggleMenu = () => {
    if(ismenuOpened){
      setIsMenuOpened(false)
    }else{
      setIsMenuOpened(true)
    }
  }

  useEffect(() => {
    const button = document.getElementById('toggleMenuButton')
    if(ismenuOpened){
      button.style.transform = ''
      document.documentElement.style.setProperty('--dashboard-menu-width', '')
    }else{
      button.style.transform = 'rotate(180deg)'
      document.documentElement.style.setProperty('--dashboard-menu-width', '50px')
    }
  }, [ismenuOpened])

  useEffect(() => {
    if(currentDbUrl && currentDbUrl !== 'dbOneAccount' && currentDbUrl !== 'dbAdmin'){
      document.getElementById(currentDbUrl).style.fontWeight = ''
    }
    dbUrl !== 'dbOneAccount' && dbUrl!== 'dbAdmin' && (document.getElementById(dbUrl).style.fontWeight = 'bold')
    setCurrentDbUrl(dbUrl)
    localStorage.setItem('dbUrl', dbUrl)

    if(isTokenExpired(token)){
      TOASTS.error('Session expirée')
      setToken('')
      localStorage.removeItem('token')
      navigate('/connexion')
    }
  }, [dbUrl])



  /**
   * 
   * MOBILE MENU
   * 
   */
  const [mobileMenuStatus, setMobileMenuStatus] = useState('closed') // Can be closed, small or big
  const [ isMovingMenu, setIsMovingMenu ] = useState(false)
  
  let startY
  let distance = 0

  const handleToggleMobileMenu = () => {
    const menu = document.getElementById('dashboardMobileMenu')
    if(mobileMenuStatus === 'closed'){
      menu.style.top = window.innerHeight - 50+'px'
      document.documentElement.style.setProperty('--mobile-menu-height',50+'px')
      menu.style.backgroundColor = ''
      menu.style.color = ''
    }else if(mobileMenuStatus === 'small'){
      const height = window.innerHeight - 100
      menu.style.top = height + 'px'
      document.documentElement.style.setProperty('--mobile-menu-height', 100+'px')
      menu.style.backgroundColor = 'var(--text-high)'
      menu.style.color = 'var(--background)'

    }
  }

  const handleTouchStart = (e) => {
    if(mobileMenuStatus !== 'big'){
      startY = e.touches[0].clientY
    }
  }
  const handleTouchMove = (e) => {
    if(startY){
      const endY = e.touches[0].clientY
      distance = startY - endY

      if(distance > 0 && distance < (window.innerHeight/2)){
        // const menu = document.getElementById('dashboardMobileMenu')
        // const height = window.innerHeight - (distance*2)
        // menu.style.top = height + 'px'
      //   menu.style.backgroundColor = 'var(--text-high)'
      //   menu.style.color = 'var(--background)'
      }
    }
  }
  const handleTouchEnd = () => {
    startY = null
    const menu = document.getElementById('dashboardMobileMenu')
    if(distance >= 75){
      menu.style.top = '-104px'
      document.documentElement.style.setProperty('--mobile-menu-height', '-104px')
      setMobileMenuStatus('big')
      distance = 0
    }else{
      if(distance <0){
        console.log('là');
        setMobileMenuStatus('closed')
      }
      distance = 0
      
      // setMobileMenuStatus(mobileMenuStatus === 'closed' ? 'small' : 'closed')
      // handleToggleMobileMenu()
    }
  }

  const preventDefaultScroll = (e) => {
    e.preventDefault()
  } 
  useEffect(() => {
    handleToggleMobileMenu()

    //disable reload page on scroll down when the mobile menu is opened and enable it when it's closed 
    if(mobileMenuStatus === 'closed'){
      document.removeEventListener('touchmove', preventDefaultScroll, {passive: false})
    }else{
      document.addEventListener('touchmove', preventDefaultScroll, {passive: false})
    }
    return () => {
      document.removeEventListener('touchmove', preventDefaultScroll, { passive: false });
    }
  }, [mobileMenuStatus])
  
  useEffect(() => {
    document.getElementById('dashboardMobileMenu').addEventListener('touchstart', handleTouchStart)
    window.addEventListener('touchmove', handleTouchMove)
    window.addEventListener('touchend', handleTouchEnd)
    return () => {
      document.getElementById('dashboardMobileMenu')?.removeEventListener('touchstart', handleTouchStart)
      window.removeEventListener('touchmove', handleTouchMove)
      window.removeEventListener('touchend', handleTouchEnd)
    }
  }, [])
  


  return (
    <>
      <div className='dashboardMenu' id='dashboardMenu'>
        <FontAwesomeIcon icon={faCaretLeft} onClick={() => toggleMenu()} id='toggleMenuButton' className='dashboardMenu__toggleButton'/>

        <div className='dashboardMenu__title'>
          <FontAwesomeIcon icon={faCloud} className='dashboardMenu__title__icon'/>
          {ismenuOpened && <h2 className='dashboardMenu__title__name'>Cloudy Pocket</h2>}
        </div>

        <nav className='dashboardMenu__nav'>
            {/* <div className='dashboardMenu__nav__item' id="dbHome" onClick={() => setDbUrl('dbHome')}><FontAwesomeIcon icon={faHouse} />{ismenuOpened && ' Tableau de bord'}</div> */}
            <div className='dashboardMenu__nav__item' id="dbAccounts" onClick={() => setDbUrl('dbAccounts')}><FontAwesomeIcon icon={faPiggyBank} />{ismenuOpened && ' Mes comptes'}</div>
            <div className='dashboardMenu__nav__item' id="dbTickets" onClick={() => setDbUrl('dbTickets')}><FontAwesomeIcon icon={faCamera} />{ismenuOpened && ' Mes tickets'}</div>
            <div className='dashboardMenu__nav__item' id='dbSettings' onClick={() => setDbUrl('dbSettings')}><FontAwesomeIcon icon={faGear} />{ismenuOpened && ' Paramètres'}</div>
            {user.role_id === 3 && <div className='dashboardMenu__nav__item' id='dbAdmin' onClick={() => setDbUrl('dbAdmin')}><FontAwesomeIcon icon={faUserTie} />{ismenuOpened && ' Administration'}</div>}
        </nav>

        <Link to='/contact'className='dashboardMenu__contact'>
          <FontAwesomeIcon icon={faEnvelope} className=''/>
          <p className=''>{ismenuOpened && ' Nous contacter'}</p>
        </Link>

      </div>

      <div className='dashboardMobileMenu' id='dashboardMobileMenu'>
        <div className='dashboardMobileMenu--closed' onClick={() => setMobileMenuStatus(mobileMenuStatus === 'closed' ? 'small' : 'closed')}>
          <FontAwesomeIcon icon={faCloud} className='dashboardMenu__title__icon'/>
        </div>
        <nav className='dashboardMobileMenu--small'>
          <FontAwesomeIcon icon={faPiggyBank} onClick={() => {setDbUrl('dbAccounts'); setMobileMenuStatus('closed')}}/>
          <FontAwesomeIcon icon={faCamera} onClick={() => {setDbUrl('dbTickets'); setMobileMenuStatus('closed')}} />
          <FontAwesomeIcon icon={faGear} onClick={() => {setDbUrl('dbSettings'); setMobileMenuStatus('closed')}} />
          {user.role_id ===3 && <FontAwesomeIcon icon={faUserTie} onClick={() => {setDbUrl('dbAdmin'); setMobileMenuStatus('closed')}} />}
        </nav>
        <div className='dashboardMobileMenu--big'>
          <FontAwesomeIcon icon={faXmark} onClick={() => setMobileMenuStatus('closed')} className='dashboardMobileMenu--big__closeButton'/>
          <nav className='dashboardMobileMenu--big__nav'>
            <div className='dashboardMobileMenu--big__nav__item' onClick={() => {setDbUrl('dbAccounts'); setMobileMenuStatus('closed')}}><FontAwesomeIcon icon={faPiggyBank}/><span>Mes Comptes</span></div>
            <div className='dashboardMobileMenu--big__nav__item' onClick={() => {setDbUrl('dbTickets'); setMobileMenuStatus('closed')}}><FontAwesomeIcon icon={faCamera}/><span>Mes Tickets</span></div>
            <div className='dashboardMobileMenu--big__nav__item' onClick={() => {setDbUrl('dbSettings'); setMobileMenuStatus('closed')}}><FontAwesomeIcon icon={faGear}/><span>Paramètres</span></div>
            {user.role_id ===  3 && <div className='dashboardMobileMenu--big__nav__item' onClick={() => {setDbUrl('dbAdmin'); setMobileMenuStatus('closed')}}><FontAwesomeIcon icon={faUserTie}/><span>Administration</span></div>}
          </nav>
          <Link to='/contact'className='dashboardMobileMenu--big__contact'>
            <FontAwesomeIcon icon={faEnvelope} className=''/>
            <p className=''>{ismenuOpened && ' Nous contacter'}</p>
          </Link>
        </div>
      </div>
      
    </>
    
  )
}

export default DBMenu