import React, {useContext} from 'react'
import {useNavigate} from 'react-router-dom'

import { GlobalContext } from '../../contexts/GlobalContext'
import { clickOutModal, closeContent } from '../../utils/functions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { API_FUNCTIONS } from '../../utils/apiFunctions'
import { TOASTS } from '../../utils/toasts'

function DeleteUserModal() {
    const {token, user, setToken, setUser} = useContext(GlobalContext)
    const navigate = useNavigate()

    const deleteUser = async() =>{
        try{
            const response = await API_FUNCTIONS.deleteUser(user.id, token)
            if(response.data?.code === 200){
                TOASTS.success('Votre compte a bien été supprimé. Au plaisir de vous revoir !')
                Object.keys(localStorage).forEach(key => {
                    localStorage.removeItem(key);
                  });
                setToken('')
                setUser('')
                navigate('/')
            }else if(response.data?.code === 401){
                TOASTS.error("Vous n'avez pas l'autorisation pour cette requête")
            }
        }catch(e){
            TOASTS.error('Oups, une erreur est survenue...')
        }
    }

  return (
    <div className='modal deleteUserModal' id='deleteUserModal' onClick={(e) => clickOutModal(e, 'deleteUserModal')}>
        <div className='modal__content deleteUserModal__content'> 
            <FontAwesomeIcon icon={faXmark} alt="fermer" onClick={() => closeContent('deleteUserModal')} className='modal__closeButton'/>
            <h2 className='danger'>Suppression du compte utilisateur</h2>
            
            <p className='deleteUserModal__description'>En supprimant votre compte, vous supprimez également toutes ses données.</p>
            <p className='deleteUserModal__description'>La suppression de votre compte est définitive</p>

            <h3 className='danger'>Voulez-vous vraiment supprimer votre compte ? </h3>

            <div className="deleteUserModal__choice ">
                <button onClick={() => deleteUser()} className='deleteUserModal__choice__button deleteUserModal__choice__button--yes'>OUI</button>
                <button onClick={() => closeContent('deleteUserModal')} className='deleteUserModal__choice__button deleteUserModal__choice__button--no'>NON</button>
            </div>
            
        </div>
    </div>
  )
}

export default DeleteUserModal