import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'

import { closeContent, clickOutModal, listenEscapeKey } from '../../utils/functions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import { GlobalContext } from '../../contexts/GlobalContext'
import { DashboardContext } from '../../contexts/DashboardContext'
import { API_FUNCTIONS } from '../../utils/apiFunctions'


function NewSubcategoryModal({account_id, addSubcategory}) {
    const {register, handleSubmit} = useForm()
    const {token} = useContext(GlobalContext)
    const {categories} = useContext(DashboardContext)

    const createSubcategory = async(data) => {
        const request = {
            ...data,
            account_id: account_id
        }

        try{
            const response = await API_FUNCTIONS.createSubcategory(request, token)
            if(response.data.code === 201){
                console.log(response.data.data)
                addSubcategory(response.data.data)
            }
        }catch(e){
            console.error(e);
        }
    }

    listenEscapeKey('subcategoryModal')
    return (
        <div className='v2modal subcategoryModal' id='subcategoryModal' onClick={(e) => clickOutModal(e, 'subcategoryModal')}>
            <div className='v2modal__content subcategoryModal__content'> 
                <FontAwesomeIcon icon={faXmark} alt="fermer" onClick={() => closeContent('subcategoryModal')}  className='v2modal__closeButton'/>

                <h2>Créer une sous-catégorie</h2>

                <form onSubmit={handleSubmit(createSubcategory)} className='subcategoryModal__form'>

                    <label>Sélectionnez la catégorie parente</label>
                    <select {...register('category_id')}> 
                        {categories.map((cat, index) => 
                            <option value={cat.id} key={index}>{cat.category_name}</option>
                        )}
                    </select>

                    <label>Nom</label>
                    <input type="text" {...register('subcategory_name')}/>
                    <button type='submit'>Créer</button>

                </form>
            </div>
        </div>
    )
}

export default NewSubcategoryModal