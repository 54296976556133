import React, { useState, useEffect, useContext } from 'react';
import { closeContent, getElementHeight, getElementWidth } from '../utils/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { API_FUNCTIONS, API_STORAGE } from '../utils/apiFunctions';
import { GlobalContext } from '../contexts/GlobalContext';

function TicketPreview({tickets, handleDeleteTicket}) {
    const { token } = useContext(GlobalContext)
    const [position, setPosition] = useState({ left: 10, top: 25, right: window.innerWidth-150, bottom: window.innerHeight-200 });
    const [isDragging, setIsDragging] = useState(false);
    const [isBorderDragging, setIsBorderDragging] = useState(false);
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const [ ticketIndex, setTicketIndex ] = useState(0)
    const [ isBorderHovered, setIsBorderHovered ] = useState('')
    const [ ticketSize, setTicketSize ] = useState({width: 0, height: 0})
    
    const handleMouseDown = (e) => {
        if(isBorderHovered !== ''){
            setIsBorderDragging(true)
        }else{
            setIsDragging(true);
            setOffset({
                x: e.clientX - position.left,
                y: e.clientY - position.top
            });
        }
    };
    

    
    const handleMouseMove = (e) => {
        const element = document.getElementById('ticketPreview')
        let left=0, top=0
        let oldLeft = position.left, oldTop = position.top
        if(e.clientX-offset.x >= window.innerWidth - element.offsetWidth){
            left = window.innerWidth - element.offsetWidth
        }else if(e.clientX-offset.x < 0){
            left=0
        }else{
            left = e.clientX - offset.x
        }

        if(e.clientY - offset.y >= window.innerHeight - element.offsetHeight){
            top = window.innerHeight - element.offsetHeight
        }else if(e.clientY - offset.y < 0){
            top = 0
        }else{
            top = e.clientY - offset.y
        }
        
        isBorderHovered === '' && setPosition({
            // x: e.clientX - offset.x >=0 ? e.clientX - offset.x : 0,
            // x: e.clientX,
            left: left,
            top: top,
            // right: left + element.offsetWidth,
            right: position.right + (oldLeft-left),
            // bottom: top + element.offsetHeight
            bottom: position.bottom + (oldTop-top)
        });
        localStorage.setItem("ticketLeft", left)
        localStorage.setItem("ticketTop", top)
        localStorage.setItem("ticketRight", position.right + (oldLeft-left))
        localStorage.setItem("ticketBottom", position.bottom + (oldTop-top))
    };
    
    const handleMouseUp = () => {
        setIsDragging(false);
    };

    function preventTextSelection(event) {
        event.preventDefault();
    }
    const handleDragStart = (e) => {
        e.preventDefault();
    };
    
    const onResize = () => {
        
        // let left = Number(localStorage.getItem('ticketLeft'))
        // let top = Number(localStorage.getItem('ticketTop'))
        // let right = Number(localStorage.getItem('ticketRight'))
        // let bottom = Number(localStorage.getItem('ticketBottom'))
        
        // const ticket = document.getElementById('ticketPreview')
        
        // if(left > window.innerWidth - ticket.offsetWidth){
        //     left = window.innerWidth - ticket.offsetWidth
        // }
        // if(top > window.innerHeight-ticket.offsetHeight){
        //     top = window.innerHeight-ticket.offsetHeight
        // }
        
        // if(left < Number(localStorage.getItem('ticketLeft')) || top < Number(localStorage.getItem('ticketTop'))){
        //     setPosition({
        //         left: left,
        //         top: top,
        //         right: left + ticket.offsetWidth,
        //         bottom: top + ticket.offsetHeight
        //     })
        //     localStorage.setItem('ticketLeft', left)
        //     localStorage.setItem('ticketTop', top)
        // }else{
        //     setPosition({
        //         left: left,
        //         top: top,
        //         right: window.innerWidth - left - ticketSize.width,
        //         bottom: window.innerHeight - top - ticketSize.height
        //     })
        // }
        setPosition({ left: 10, top: 25, right: window.innerWidth-150, bottom: window.innerHeight-200 })
    }
    useEffect(() => {
        localStorage.setItem('ticketLeft', position.left)
        localStorage.setItem('ticketTop', position.top)
        localStorage.setItem('ticketRight', position.right)
        localStorage.setItem('ticketBottom', position.bottom)

        setTicketSize({
            width : window.innerWidth- position.right - position.left,
            height: window.innerHeight - position.bottom - position.top
        })
    }, [position])

    
    // listening window resize
    useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, [])
    
    useEffect(() => {
        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
            window.addEventListener('selectstart', preventTextSelection);
            return () => {
                window.removeEventListener('selectstart', preventTextSelection);
                window.removeEventListener('mousemove', handleMouseMove);
                window.removeEventListener('mouseup', handleMouseUp);
            };
        }
    }, [isDragging]);

    


    // function to resize ticket

    const handleSearchBorders = (e) => {
        const mouseX = e.nativeEvent.layerX
        const mouseY = e.nativeEvent.layerY
        const element = document.getElementById('ticketPreview')
        const width = element.offsetWidth
        const height = element.offsetHeight

        let state = ''
        if(mouseY<= 10)state='T';
        if(mouseY>= height - 10)state='B';
        if(mouseX<= 10)state+='L';
        if(mouseX>= width-10)state+='R';

        setIsBorderHovered(state)
    }
    

    useEffect(() => {
        const element = document.getElementById('ticketPreview')
        
        if(isBorderHovered === "T" || isBorderHovered === "B"){
            element.style.cursor = "n-resize"
        }else if(isBorderHovered === "L" || isBorderHovered === "R"){
            element.style.cursor="e-resize"
        }else if(isBorderHovered==="TL" || isBorderHovered === "BR"){
            element.style.cursor = "nw-resize"
        }else if(isBorderHovered === "TR" || isBorderHovered === "BL"){
            element.style.cursor = "ne-resize"
        }else{
            element.style.cursor = ""
        }
    }, [isBorderHovered])


    const handleMouseMoveBorder = (e) =>{
        if(isBorderDragging){
            const element = document.getElementById('ticketPreview')
            let left = position.left
            let top = position.top
            let right = position.right
            let bottom = position.bottom

            if(isBorderHovered.includes('L')){
                left = e.clientX
            }
            if(isBorderHovered.includes('T')){
                top = e.clientY
            }
            if(isBorderHovered.includes('R')){
                right = window.innerWidth-e.clientX
            }
            if(isBorderHovered.includes('B')){
                bottom = window.innerHeight-e.clientY
            }
            
            // element.style.width = right - left
            // element.style.height = bottom - top 
            setPosition({
                left: left,
                top: top,
                right: right,
                bottom: bottom
            })
        }
    }
    const handleMouseUpBorder = () => {
        setIsBorderDragging(false)
    }
    useEffect(() => {
        if (isBorderDragging) {
            window.addEventListener('mousemove', handleMouseMoveBorder);
            window.addEventListener('mouseup', handleMouseUpBorder);
            return () => {
                window.removeEventListener('mousemove', handleMouseMoveBorder);
                window.removeEventListener('mouseup', handleMouseUpBorder);
            };
        }
    }, [isBorderDragging]);


    

    // Carrousel functions 

    const previousTicket = () => {
        if(ticketIndex === 0){
            setTicketIndex(tickets.length - 1)
        }else{
            setTicketIndex(ticketIndex-1)
        }
    }
    const nextTicket = () => {
        if(ticketIndex === tickets.length-1){
            setTicketIndex(0)
        }else{
            setTicketIndex(ticketIndex+1)
        }
    }

    const deleteTicket = async(id) => {
        try{
            const response = await API_FUNCTIONS.deleteTicket(id, token)

            if(response.data?.code === 200){
                handleDeleteTicket(id)
                ticketIndex > 0 && setTicketIndex(ticketIndex-1)
            }
        }catch(e){
            console.error(e);
        }
    }

    


    return (
        <div
            className="ticketPreview"
            style={{ left: position.left, top: position.top, right: position.right, bottom: position.bottom}}
            onMouseDown={handleMouseDown}
            onMouseMove={e => handleSearchBorders(e)}
            onMouseLeave={() => setIsBorderHovered('')}
            id='ticketPreview'
        >
            <FontAwesomeIcon icon={faXmark} className='danger ticketPreview__closeBtn' onClick={() => {closeContent('ticketPreview'); setPosition({ left: 10, top: 25, right: window.innerWidth-150, bottom: window.innerHeight-200 })}}/>

            {tickets.length > 0 ?
                <div className='ticketPreview__container'>
                    { tickets.length >1 && <FontAwesomeIcon icon={faChevronLeft} className='ticketPreview__container__previous' onClick={() => previousTicket()}/>}
                    <img src={API_STORAGE + tickets[ticketIndex].ticket_name} alt={tickets[ticketIndex].ticket_name + ' - ' + tickets[ticketIndex].created_at} className='ticketPreview__container__img' onDragStart={(e) => handleDragStart(e)} />
                    { tickets.length >1 && <FontAwesomeIcon icon={faChevronRight} className='ticketPreview__container__next' onClick={() => nextTicket()}/>}

                </div>
            :
                <p>Il n'y a aucun ticket à afficher...</p>
            }

            {tickets.length ? <FontAwesomeIcon icon={faTrash} className='danger ticketPreview__deleteBtn' onClick={() => deleteTicket(tickets[ticketIndex].id)}/> : null}
        </div>
    );
}

export default TicketPreview;