import React from 'react'
import { PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faSquareCheck } from '@fortawesome/free-solid-svg-icons';

function PDFExport({account, transactions, categories, subcategories, transactionTypes, }) {
    const styles = StyleSheet.create({
        table: { width: '100%', marginTop: 10, marginBottom: 10 },
        tableRow: { flexDirection: 'row' },
        tableHeader: { backgroundColor: '#eeeeee' },
        tableCell: { padding: 5 },
      });

    // const styles = StyleSheet.create({
    //     table: {
    //       display: 'table',
    //       width: 'auto',
    //       borderStyle: 'solid',
    //       borderWidth: 1,
    //       borderRightWidth: 0,
    //       borderBottomWidth: 0,
    //     },
    //     tableRow: {
    //       margin: 'auto',
    //       flexDirection: 'row',
    //     },
    //     tableCell: {
    //       margin: 'auto',
    //       marginTop: 5,
    //       fontSize: 10,
    //       padding: 4,
    //       borderStyle: 'solid',
    //       borderWidth: 1,
    //       borderLeftWidth: 0,
    //       borderTopWidth: 0,
    //     },
    //   });

  return (
    <Document>
        <Page>
            <View>
                <Text >{account.account_name}</Text>
            </View>


            {/* <View style={styles.table}>
                <View style={styles.tableRow}>
                    <View style={styles.tableCell}>
                        <Text>Date</Text>
                    </View>
                    <View style={styles.tableCell}>
                        <Text>Mode</Text>
                        
                    </View>
                    <View style={styles.tableCell}>
                        <Text>Tiers</Text>
                    </View>
                    <View style={styles.tableCell}>
                        <Text>Catégorie</Text>
                    </View>
                    <View style={styles.tableCell}>
                        <Text>Montant</Text>
                        
                    </View>
                    <View style={styles.tableCell}>
                        <Text></Text>
                    </View>
                </View>


                {transactions.map((t, index) => (
                    <View style={styles.tableRow} key={index}>
                        <View style={styles.tableCell}>
                            <Text>Date</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text>Mode</Text>
                            
                        </View>
                        <View style={styles.tableCell}>
                            <Text>Tiers</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text>Catégorie</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text>Montant</Text>
                            
                        </View>
                        <View style={styles.tableCell}>
                            <Text></Text>
                        </View>
                    </View>
                ))}
            </View> */}
            
            
            {/* <View style={styles.table}>
                <View style={[styles.tableRow, styles.tableHeader]}>
                    <Text style={[styles.tableCell]}>Date</Text>
                    <Text style={[styles.tableCell]}>Mode</Text>
                    <Text style={[styles.tableCell]}>Tiers</Text>
                    <Text style={[styles.tableCell]}>Catégorie</Text>
                    <Text style={[styles.tableCell]}>Montant</Text>
                    <Text style={[styles.tableCell]}></Text>
                </View>
                {transactions.map((t, index) => (
                <View key={index} style={styles.tableRow}>
                    <Text style={[styles.tableCell]}>{t.transaction_date}</Text>
                    <Text style={[styles.tableCell]}>{transactionTypes.find(item => item.id === t.transaction_type_id).transaction_type_name}</Text>
                    <Text style={[styles.tableCell]}>{t.transfer_with || t.counterparty}</Text>
                    <Text style={[styles.tableCell]}>{categories.find(item => item.id === t.category_id).category_name}</Text>
                    <Text style={[styles.tableCell]}>{t.transaction_amount}</Text>
                </View>
                ))}
            </View> */}
        </Page>
    </Document>
  )
}

export default PDFExport