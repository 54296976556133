import React, {useState, useContext} from 'react'
import { useForm } from "react-hook-form"
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { clickOutModal, closeContent, listenEscapeKey } from '../../utils/functions'
import { GlobalContext } from '../../contexts/GlobalContext'
import { API_FUNCTIONS } from '../../utils/apiFunctions'
import { TOASTS } from '../../utils/toasts'

function UpdatePasswordModal() {
    const {register, handleSubmit, reset,  formState: {errors} } = useForm()
    const { token, setToken, user } = useContext(GlobalContext)

    const updatePassword = async(data) => {

        try{
            const request = {
                ...user,
                password: data.newPassword === data.confirmPassword ? data.newPassword : null,
                old_password: data.oldPassword
            }
            const response = await API_FUNCTIONS.updateUser(request, token)
            if(response.data?.code === 200){
                setToken(response.data.token)
                localStorage.setItem('token', response.data.token)
                reset()
                closeContent('updatePasswordModal')
                TOASTS.success('Votre mot de passe a bien été modifié')
            }else{
                TOASTS.error('Oups, une erreur est survenue lors de la modification du mot de passe...')
            }
        }catch(e){
            TOASTS.error('Oups, une erreur est survenue lors de la modification du mot de passe...')
            console.error(e);
        }
    }

    listenEscapeKey('updatePasswordModal')
    return (
        <div className='modal' id='updatePasswordModal' onClick={(e) => clickOutModal(e, 'updatePasswordModal')}>
            <div className='modal__content'> 
                <FontAwesomeIcon icon={faXmark} alt="fermer" onClick={() => closeContent('updatePasswordModal')}  className='modal__closeButton'/>
                <h2 className='success'>Modifier le mot de passe</h2>

                <form id="updatePasswordForm" onSubmit={handleSubmit(updatePassword)}>

                    <label>Votre mot de passe actuel</label>
                    <input type="password" autoComplete='new-password' {...register('oldPassword', {required: 'Vous devez renseigner votre mot de passe actuel'})}/>
                    {errors.oldPassword && <p className='danger'>{errors.oldPassword.message}</p>}

                    <label>Nouveau mot de passe</label>
                    <input type="password" {...register('newPassword', {required: 'Vous devez renseigner votre mot de passe actuel'})}/>
                    {errors.newPassword && <p className='danger'>{errors.newPassword.message}</p>}

                    <label>Confirmez le mot de passe</label>
                    <input type="password" {...register('confirmPassword', {required: 'Vous devez renseigner votre mot de passe actuel'})}/>
                    {errors.confirmPassword && <p className='danger'>{errors.confirmPassword.message}</p>}


                    <button type='submit' >Envoyer</button>

                </form>



            </div>
        </div>
    )
}

export default UpdatePasswordModal