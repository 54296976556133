import React, {useContext, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { GlobalContext } from '../contexts/GlobalContext'
import { API_FUNCTIONS } from '../utils/apiFunctions'
import { TOASTS } from '../utils/toasts'
import { autoScroll } from '../utils/functions'
import { Helmet } from 'react-helmet'

function Login() {
  const navigate = useNavigate()
  const {register, handleSubmit, formState: {errors}} = useForm()

  const {token, setToken, setTheme, setUser, setUrl} = useContext(GlobalContext)

  setUrl(useLocation().pathname)
  useEffect(() => {
    if(token)navigate('/')
  }, [token])

  useEffect(() => {
    autoScroll()
  }, [])

  const userLogin = async(data) => {
    try{
      const response = await API_FUNCTIONS.login(data)

      if(response){
        localStorage.setItem('token', response.data.access_token.token)
        setToken(response.data.access_token.token)
        setTheme(response.data.user.theme_id)
        setUser(response.data.user)
        localStorage.setItem('colorTheme', response.data.user.theme_id)
        document.getElementById('loginForm').reset()
        navigate('/dashboard')
        TOASTS.success('Bienvenue '+response.data.user.firstname + ' ' + response.data.user.lastname[0].toUpperCase())
      }else{
        TOASTS.error('Problème lors de la connexion')
      }

    }catch(e){
      TOASTS.error('Problème lors de la connexion')
    }
  }

  return (
    <div className='mainContainer login'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Connexion - Cloudy Pocket</title>
      </Helmet>
      <h1>Login</h1>

      <form onSubmit={handleSubmit(userLogin)} id="loginForm" className='loginForm'>
          <input type="text" id='loginEmail'{...register('email',  {required: "Ce champ est obligatoire"})} placeholder=''/>
          <label htmlFor="loginEmail">email</label>
          {errors.email && <span className='danger loginForm__error'>{errors.email.message}</span>}
          
          <input type="password" id='loginPassword'{...register('password', {required: "Ce champ est obligatoire"})} placeholder=''/>
          <label htmlFor="loginPassword">mot de passe</label>
          {errors.password && <span className='danger loginForm__error'>{errors.password.message}</span>}

          <button type='submit'>Se connecter</button>
      </form>
      
    </div>
  )
}

export default Login